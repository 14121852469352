import Api from './api';
import Store from '../store';
export default {
    // GET
    get() {
        // Sort out whats in the store
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        const current_page = Store.getters['wishlists/getPage'] ? Store.getters['wishlists/getPage'] : 1;
        const search_term = Store.getters['wishlists/getSearchTerm'] ? Store.getters['wishlists/getSearchTerm'] : null;
        const filter = Store.getters['wishlists/getFilter'] ? Store.getters['wishlists/getFilter'] : null;
        // Sort out the URL for the wishlist response
        let url = `/wishlists?per_page=${per_page}`;
        if (current_page) {
            url += `&page=${current_page}`;
        }
        if (search_term && search_term != '') {
            url += `&q=${search_term}`;
        }
        if (filter && filter != '') {
            url += `&label=${filter}`;
        }
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    getModalWishlists() {
        // Sort out whats in the store
        const per_page = 50;
        const current_page = Store.getters['wishlists/getPage'] ? Store.getters['wishlists/getPage'] : 1;
        const search_term = Store.getters['wishlists/getSearchTerm'] ? Store.getters['wishlists/getSearchTerm'] : null;
        const filter = Store.getters['wishlists/getFilter'] ? Store.getters['wishlists/getFilter'] : null;
        // Sort out the URL for the wishlist response
        let url = `/wishlists?per_page=${per_page}`;
        if (current_page) {
            url += `&page=${current_page}`;
        }
        if (search_term && search_term != '') {
            url += `&q=${search_term}`;
        }
        if (filter && filter != '') {
            url += `&label=${filter}`;
        }
        // console.log(url)
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    getBySlug(slug) {
        return Api()
            .get(`/wishlists/${slug}`)
            .then((response) => response.data);
    },
    // GET WISHLIST LODGES
    getWishlistLodges(wishlist_slug) {
        // Sort out the URL for the lodges response
        let url = `/wishlists/${wishlist_slug}/get-wishlist-Items?item_type=lodge`;
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    // GET WISHLIST PACKAGES
    getWishlistPackages(wishlist_slug) {
        // Sort out the URL for the packages response
        let url = `/wishlists/${wishlist_slug}/get-wishlist-Items?item_type=package`;
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    // POST
    create(formData) {
        return Api()
            .post('/wishlists', formData, {
            headers: {
                Accept: 'application/json',
                withCredentials: true,
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${Store.state.auth.token}`,
            },
        })
            .then((response) => response.data);
    },
    // POST
    addOrRemoveItem(formData) {
        return Api()
            .post('/wishlists/add-or-remove', formData, {
            headers: {
                Accept: 'application/json',
                withCredentials: true,
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${Store.state.auth.token}`,
            },
        })
            .then((response) => response.data);
    },
    update(formData, slug) {
        return Api()
            .post(`/wishlists/${slug}`, formData, {
            headers: {
                Accept: 'application/json',
                withCredentials: true,
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${Store.state.auth.token}`,
            },
        })
            .then((response) => response.data);
    },
    delete(slug) {
        return Api()
            .delete(`/wishlists/${slug}`)
            .then((response) => response.data);
    }
};
