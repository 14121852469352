/**
 * Simple permissions check for template items.
 *
 * eg. v-can="'lodge.edit'"
 */
import Store from '@/store';
function process(el, binding, vnode) {
    const index = Store.getters['user/permissions'].indexOf(binding.value);
    if (index === -1) {
        if (vnode.elm && vnode.elm.parentElement) {
            vnode.elm.parentElement.removeChild(vnode.elm);
        }
    }
}
export default {
    bind: process,
    inserted: process,
};
