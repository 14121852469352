var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-h-screen bg-ag-offwhite max-w-full overflow-x-hidden md:bg-white",class:{'overflow-hidden max-h-screen' : _vm.notificationDisplay && _vm.notificationPosition === 'center'},attrs:{"id":"app"}},[(_vm.notificationDisplay && _vm.notificationPosition === 'center')?_c('div',{staticClass:"transition ease-in delay-100 fixed inset-0 w-screen h-screen bg-black opacity-50 z-60"}):_vm._e(),_c('div',{staticClass:"min-h-screen flex flex-col relative",class:{
            'md:max-w-screen-xxl md:mx-auto':
                _vm.$route.name !== 'landing-page' &&
                _vm.$route.name !== 'register' &&
                _vm.$route.name !== 'login' &&
                _vm.$route.name !== 'onboarding' &&
                _vm.$route.name !== 'subscribe',
        }},[(
                _vm.$route.name !== 'landing-page' &&
                _vm.$route.name !== 'register' &&
                _vm.$route.name !== 'login' &&
                _vm.$route.name !== 'onboarding' &&
                _vm.$route.name !== 'subscribe'
            )?_c('navigation'):_vm._e(),_c('transition',{attrs:{"enter-active-class":"transform ease-out duration-300 transition","enter-class":"translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2","enter-to-class":"translate-y-0 opacity-100 sm:translate-x-0","leave-active-class":"transition ease-in duration-100","leave-class":"opacity-100","leave-to-class":"opacity-0","mode":"in-out"}},[_c('notification')],1),_c('router-view',{key:_vm.$route.fullPath,staticClass:"flex-1 text-gray-900"}),(
                _vm.$route.name !== 'landing-page' &&
                _vm.$route.name !== 'register' &&
                _vm.$route.name !== 'forgot' &&
                _vm.$route.name !== 'login' &&
                _vm.$route.name !== 'onboarding' &&
                _vm.$route.name !== 'subscribe' &&
                _vm.$route.name !== 'my-village'
            )?_c('div',{staticClass:"px-4 w-full relative max-w-full md:max-w-screen-md lg:max-w-screen-lg md:mx-auto pb-10 md:flex md:flex-row-reverse md:items-center"},[_c('div',{staticClass:"flex items-center mb-2 justify-end md:absolute md:mx-4 md:right-0 md:mb-0"},[_c('span',{staticClass:"cursor-pointer font-light mr-2 text-ag-dark-grey text-xs",on:{"click":function($event){return _vm.backToTop()}}},[_vm._v("Back to top")]),_c('div',{staticClass:"cursor-pointer flex items-center justify-center w-8 h-8 bg-ag-red text-white rounded-full",attrs:{"data-cy":"back-to-top"},on:{"click":function($event){return _vm.backToTop()}}},[_c('font-awesome-icon',{staticClass:"text-base",attrs:{"icon":['fal', 'chevron-up']}})],1)]),_c('footer-nav',{staticClass:"hidden md:flex"})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }