import { createNamespacedHelpers } from 'vuex';
import ConversationService from '@/services/conversations.ts';
import { UserProfileModel } from '@/models/User.model';
// Inital state
const state = {
    conversations: [],
    perPage: 100,
    activeConversation: {
        id: '',
        from_user_id: '',
        to_user_id: '',
        message: '',
        created_at: '',
        updated_at: '',
        deleted_at: null,
        sender: new UserProfileModel(),
        receiver: new UserProfileModel(),
    },
    activeConversationMember: new UserProfileModel(),
};
const getters = {
    getConversations: (state) => state.conversations,
    activeConversation: (state) => state.activeConversation,
    activeConversationMember: (state) => state.activeConversationMember,
    perPage: (state) => state.perPage,
};
const mutations = {
    SET_EMPTY: (state) => {
        state.conversations = [];
        state.activeConversationMember = new UserProfileModel(),
            state.activeConversation = {
                id: '',
                from_user_id: '',
                to_user_id: '',
                message: '',
                created_at: '',
                updated_at: '',
                deleted_at: null,
                sender: new UserProfileModel(),
                receiver: new UserProfileModel(),
            };
    },
    SET_PER_PAGE: (state, perPage) => (state.perPage = perPage),
    SET_CONVERSATIONS: (state, conversations) => (state.conversations = conversations),
    SET_ACTIVE_CONVERSATION: (state, conversation) => (state.activeConversation = conversation),
    SET_ACTIVE_CONVERSATION_MEMBER: (state, member) => (state.activeConversationMember = member),
};
const actions = {
    async getAllConversations({}) {
        try {
            const response = await ConversationService.get(5000);
            this.commit('conversations/SET_CONVERSATIONS', response.data.conversations);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    // Takes the currently set values for search, filter and pagination
    // and returns a list of conversation for display
    // BEFORE calling this function be sure to set the filter,search and page values
    async getConversations() {
        try {
            let perPage = this.getters["conversations/perPage"];
            const response = await ConversationService.get(perPage);
            this.commit('conversations/SET_CONVERSATIONS', response.data.conversations);
            this.dispatch('user/resetSingleResourceCount', 'messages');
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getConversationBySlug({}, slug) {
        try {
            const response = await ConversationService.getBySlug(slug);
            this.commit('conversations/SET_ACTIVE_CONVERSATION', response.data.conversation);
            this.commit('conversations/SET_ACTIVE_CONVERSATION_MEMBER', response.data.sender);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setActiveConversation({ commit, getters, dispatch }, conversation) {
        try {
            this.commit('conversations/SET_ACTIVE_CONVERSATION', conversation);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setActiveConversationMember({ commit, getters, dispatch }, user) {
        try {
            this.commit('conversations/SET_ACTIVE_CONVERSATION_MEMBER', user);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async createMessage({}, form) {
        try {
            const response = await ConversationService.create(form);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async deleteMessage({}, id) {
        try {
            const response = await ConversationService.delete(id);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async deleteConversation({}, id) {
        try {
            const response = await ConversationService.deleteConversation(id);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('ConversationsModule');
export const ConversationsModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
