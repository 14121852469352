import { createNamespacedHelpers } from 'vuex';
import EngagementsService from '@/services/engagements.ts';
import Store from '@/store';
// Inital state
const state = {
    engagementType: '',
    engagementSlug: ''
};
const getters = {
    getEngagementType: (state) => state.engagementType,
    getEngagementSlug: (state) => state.engagementSlug,
};
const mutations = {
    SET_ENGAGEMENT_TYPE: (state, type) => (state.engagementType = type),
    SET_ENGAGEMENT_SLUG: (state, slug) => (state.engagementSlug = slug),
};
const actions = {
    async agree({}, agreeable) {
        try {
            const response = await EngagementsService.agree(agreeable);
            await this.dispatch('engagements/saveResponseToStore', response);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async disagree({}, disagreeable) {
        try {
            const response = await EngagementsService.disagree(disagreeable);
            await this.dispatch('engagements/saveResponseToStore', response);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async report({}, reportable) {
        try {
            const response = await EngagementsService.report(reportable);
            await this.dispatch('engagements/saveResponseToStore', response);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async createComment({}, commentable) {
        try {
            const response = await EngagementsService.comment(commentable);
            await this.dispatch('engagements/saveResponseToStore', response);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async updateComment({}, form) {
        try {
            const response = await EngagementsService.updateComment(form);
            await this.dispatch('engagements/saveResponseToStore', response);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async deleteComment({}, id) {
        try {
            const response = await EngagementsService.deleteComment(id);
            await this.dispatch('engagements/saveResponseToStore', response);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setEngagementType({}, type) {
        this.commit('engagements/SET_ENGAGEMENT_TYPE', type.engagementType);
        this.commit('engagements/SET_ENGAGEMENT_SLUG', type.engagementSlug);
    },
    async saveResponseToStore({}, response) {
        try {
            const engagementType = await Store.getters['engagements/getEngagementType'];
            const engagementSlug = await Store.getters['engagements/getEngagementSlug'];
            // if response is a comment
            if (response.data.comment) {
                if (engagementType === 'discussion') {
                    await Store.dispatch('discussions/getDiscussionBySlug', engagementSlug);
                }
                else if (engagementType === 'story') {
                    await Store.dispatch('stories/getStoryBySlug', engagementSlug);
                }
            }
            // if response is a discussion setActiveDiscussion  
            if (response.data.discussion && engagementType === 'discussion') {
                await Store.dispatch('discussions/setActiveDiscussion', response.data.discussion);
            }
            // if response is a story setActiveStory  
            if (response.data.story && engagementType === 'story') {
                await Store.dispatch('stories/setActiveStory', response.data.story);
            }
        }
        catch (e) {
            console.log(`Engagements`, e);
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('EngagementsModule');
export const EngagementsModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
