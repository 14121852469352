import { createNamespacedHelpers } from 'vuex';
import DiscussionService from '@/services/discussions.ts';
import Store from '@/store';
// Inital state
const state = {
    discussions: [],
    filter: '',
    searchTerm: '',
    page: 0,
    onlyFavourites: false,
    activeDiscussion: {
        id: '',
        user_id: '',
        user: null,
        title: '',
        slug: '',
        discussion: '',
        agree: 0,
        disagree: 0,
        has_restricted_words: false,
        submit_restricted_words: false,
        is_travel_related: false,
        admin_approved: '',
        admin_edited: false,
        admin_edited_at: '',
        published_at: '',
        video_embed_url: '',
        user_edited: false,
        user_edited_at: '',
        deleted_at: '',
        comments_count: 0,
        labels: [],
        images: [],
        agrees: [],
        disagrees: [],
        favorite_discussions: [],
        reports: [],
        comments: [],
    }
};
const getters = {
    getDiscussions: (state) => state.discussions,
    activeDiscussion: (state) => state.activeDiscussion,
    getFilter: (state) => state.filter,
    getSearchTerm: (state) => state.searchTerm,
    getPage: (state) => state.page,
    getOnlyFavourites: (state) => state.onlyFavourites
};
const mutations = {
    SET_EMPTY: (state) => (state.discussions = []),
    SET_DISCUSSIONS: (state, discussions) => (state.discussions = discussions),
    SET_ACTIVE_DISCUSSION: (state, discussion) => (state.activeDiscussion = discussion),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_SEARCH_TERM: (state, searchTerm) => (state.searchTerm = searchTerm),
    SET_PAGE: (state, page) => (state.page = page),
    SET_ONLY_FAVOURITES: (state, onlyFavourites) => (state.onlyFavourites = onlyFavourites),
};
const actions = {
    async getAllDiscussions({}) {
        try {
            const response = await DiscussionService.get();
            this.commit('discussions/SET_DISCUSSIONS', response.data.discussions);
            this.dispatch('pagination/setPagination', response.data.pagination);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setFilter({ commit }, filter) {
        try {
            this.commit('discussions/SET_FILTER', filter);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setSearch({ commit }, search) {
        try {
            this.commit('discussions/SET_SEARCH_TERM', search);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setPage({ commit }, page) {
        try {
            this.commit('discussions/SET_PAGE', page);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setOnlyFavourites({ commit }, onlyFavourites) {
        try {
            this.commit('discussions/SET_ONLY_FAVOURITES', onlyFavourites);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    // Takes the currently set values for search, filter and pagination
    // and returns a list of discussions for display
    // BEFORE calling this function be sure to set the filter,search and page values
    async getDiscussions() {
        try {
            const response = await DiscussionService.get();
            this.commit('discussions/SET_DISCUSSIONS', response.data.discussions);
            this.dispatch('pagination/setPagination', response.data.pagination);
            this.dispatch('user/resetSingleResourceCount', 'discussions');
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getDiscussionBySlug({}, slug) {
        try {
            const response = await DiscussionService.getBySlug(slug);
            if (response.data.discussion.video_embed_url != null && response.data.discussion.video_embed_url != "") {
                response.data.discussion.images.unshift({
                    id: 'Video Embed',
                    imageable_id: 'Video Embed',
                    imageable_type: 'Video Embed',
                    disk: 'Video Embed',
                    filename: 'Video Embed',
                    public_url: response.data.discussion.video_embed_url,
                    type: 'video',
                    created_at: "",
                    updated_at: "",
                });
            }
            this.commit('discussions/SET_ACTIVE_DISCUSSION', response.data.discussion);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setActiveDiscussion({ commit, getters, dispatch }, discussion) {
        try {
            this.commit('discussions/SET_ACTIVE_DISCUSSION', discussion);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async favourite({}, slug) {
        try {
            const response = await DiscussionService.favourite(slug);
            await Store.dispatch('discussions/setActiveDiscussion', response.data.discussion);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data.discussion;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async create({}, formData) {
        try {
            const response = await DiscussionService.create(formData);
            await this.dispatch('notification/showSuccess', { message: response.message, isSticky: true });
            return response.data.discussion;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async update({}, payload) {
        try {
            const response = await DiscussionService.update(payload.formData, payload.slug);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data.discussion;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async delete({}, slug) {
        try {
            const response = await DiscussionService.delete(slug);
            await this.dispatch('notification/showSuccess', response.message);
            return;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('DiscussionsModule');
export const DiscussionsModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
