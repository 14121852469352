import Api from './api';
import Store from '../store';
export default {
    // GET
    get() {
        return Api()
            .get('/questions')
            .then((response) => response.data);
    },
    // POST
    submitQuestions(formData) {
        return Api()
            .post('/questions/submit-answers', formData, {
            headers: {
                Accept: 'application/json',
                withCredentials: true,
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${Store.state.auth.token}`,
            },
        })
            .then((response) => response.data);
    }
};
