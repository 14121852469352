import { createNamespacedHelpers } from 'vuex';
import MembersService from '@/services/members';
import UserService from '@/services/user';
// Inital state
const state = {
    members: [],
    filter: '',
    searchTerm: '',
    page: 0,
    activeMember: {
        id: null,
        name: '',
        known_as: '',
        email: '',
        email_change: null,
        free_tier: false,
        description: '',
        country: {
            code: '',
            name: ''
        },
        country_code: '',
        city: '',
        avatar: {
            filename: '',
            public_url: ''
        },
        phone: '',
        address: '',
        company_name: '',
        company_phone: '',
        company_address: '',
        labels: [],
        images: [],
        roles: [],
        permissions: [],
        blocked_members: [],
        email_verified_at: null,
        last_visit: null,
        cooldown_ends: null,
        admin_verified: null,
        onboarding: true,
        email_notification_messages: null,
        tribe_subscription: null,
        project_subscriptions: null,
        created_at: null,
        updated_at: null,
        deleted_at: null
    }
};
const getters = {
    getMembers: (state) => state.members,
    activeMember: (state) => state.activeMember,
    getFilter: (state) => state.filter,
    getSearchTerm: (state) => state.searchTerm,
    getPage: (state) => state.page,
};
const mutations = {
    SET_EMPTY: (state) => {
        state.members = [];
    },
    SET_MEMBERS: (state, members) => (state.members = members),
    SET_ACTIVE_MEMBER: (state, member) => (state.activeMember = member),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_SEARCH_TERM: (state, searchTerm) => (state.searchTerm = searchTerm),
    SET_PAGE: (state, page) => (state.page = page),
};
const actions = {
    async getAllMembers({}) {
        try {
            const response = await MembersService.get();
            this.commit('members/SET_MEMBERS', response.data.members);
            this.dispatch('pagination/setPagination', response.data.pagination);
            this.dispatch('user/resetSingleResourceCount', 'members');
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setFilter({ commit }, filter) {
        try {
            this.commit('members/SET_FILTER', filter);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setSearch({ commit }, search) {
        try {
            this.commit('members/SET_SEARCH_TERM', search);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setPage({ commit }, page) {
        try {
            this.commit('members/SET_PAGE', page);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    // Takes the currently set values for search, filter and pagination
    // and returns a list of members for display
    // BEFORE calling this function be sure to set the filter,search and page values
    async getMembers() {
        try {
            const response = await MembersService.get();
            this.commit('members/SET_MEMBERS', response.data.members);
            this.dispatch('pagination/setPagination', response.data.pagination);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getMemberBySlug({}, slug) {
        try {
            const response = await MembersService.getBySlug(slug);
            this.commit('members/SET_ACTIVE_MEMBER', response.data.member);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async blockMember({}, blocked_member_id) {
        try {
            const response = await MembersService.blockMember(blocked_member_id);
            await this.dispatch('notification/showSuccess', response.message);
            const profileResponse = await UserService.getUserProfile();
            await this.commit('user/SET_PROFILE', profileResponse.data.profile);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setActiveMember({ commit, getters, dispatch }, member) {
        try {
            this.commit('members/SET_ACTIVE_MEMBER', member);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('MembersModule');
export const MembersModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
