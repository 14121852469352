import { createNamespacedHelpers } from 'vuex';
import StoriesService from '@/services/stories.ts';
import Store from '@/store';
// Inital state
const state = {
    stories: [],
    filter: '',
    searchTerm: '',
    page: 0,
    onlyFavourites: false,
    activeStory: {
        id: '',
        author_id: '',
        author: null,
        title: '',
        slug: '',
        hero_image_id: '',
        hero_image_copyright: '',
        excerpt: '',
        reading_time: 0,
        is_travel_related: false,
        hero_image: {
            id: '',
            imageable_id: '',
            imageable_type: '',
            disk: '',
            type: '',
            filename: '',
            public_url: '',
            created_at: '',
            updated_at: ''
        },
        storyblocks: [],
        agree: 0,
        disagree: 0,
        published_at: '',
        deleted_at: '',
        comments_count: 0,
        images: [],
        agrees: [],
        disagrees: [],
        comments: [],
        labels: [],
        favorite_stories: []
    }
};
const getters = {
    getStories: (state) => state.stories,
    activeStory: (state) => state.activeStory,
    getFilter: (state) => state.filter,
    getSearchTerm: (state) => state.searchTerm,
    getPage: (state) => state.page,
    getOnlyFavourites: (state) => state.onlyFavourites
};
const mutations = {
    SET_EMPTY: (state) => (state.stories = []),
    SET_STORIES: (state, stories) => (state.stories = stories),
    SET_ACTIVE_STORY: (state, story) => (state.activeStory = story),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_SEARCH_TERM: (state, searchTerm) => (state.searchTerm = searchTerm),
    SET_PAGE: (state, page) => (state.page = page),
    SET_ONLY_FAVOURITES: (state, onlyFavourites) => (state.onlyFavourites = onlyFavourites),
};
const actions = {
    async getAllStories({}) {
        try {
            const response = await StoriesService.get();
            this.commit('stories/SET_STORIES', response.data.stories);
            this.dispatch('pagination/setPagination', response.data.pagination);
            this.dispatch('user/resetSingleResourceCount', 'stories');
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setFilter({ commit }, filter) {
        try {
            this.commit('stories/SET_FILTER', filter);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setSearch({ commit }, search) {
        try {
            this.commit('stories/SET_SEARCH_TERM', search);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setPage({ commit }, page) {
        try {
            this.commit('stories/SET_PAGE', page);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setOnlyFavourites({ commit }, onlyFavourites) {
        try {
            this.commit('stories/SET_ONLY_FAVOURITES', onlyFavourites);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    // Takes the currently set values for search, filter and pagination
    // and returns a list of stories for display
    // BEFORE calling this function be sure to set the filter,search and page values
    async getStories() {
        try {
            const response = await StoriesService.get();
            this.commit('stories/SET_STORIES', response.data.stories);
            this.dispatch('pagination/setPagination', response.data.pagination);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getStoryBySlug({}, slug) {
        try {
            const response = await StoriesService.getBySlug(slug);
            this.commit('stories/SET_ACTIVE_STORY', response.data.story);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setActiveStory({ commit, getters, dispatch }, story) {
        try {
            this.commit('stories/SET_ACTIVE_STORY', story);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setActiveStoryBySlug({ commit, getters, dispatch }, slug) {
        if (!state.activeStory || !state.activeStory.slug) {
            await this.dispatch('stories/getStoryBySlug', slug);
            const story = state.activeStory;
        }
    },
    async favourite({}, slug) {
        try {
            const response = await StoriesService.favourite(slug);
            await Store.dispatch('stories/setActiveStory', response.data.story);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data.story;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('StoriesModule');
export const StoriesModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
