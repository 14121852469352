import Api from './api';
export default {
    // GET
    getCountries() {
        return Api()
            .get('/countries')
            .then((response) => response.data);
    },
    // GET
    getCurrencies() {
        return Api()
            .get('/currencies')
            .then((response) => response.data);
    },
    // DELETE 
    deleteImage(image) {
        return Api()
            .delete(`/images`, { data: image })
            .then((response) => response.data);
    },
};
