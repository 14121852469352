export class UserProfileModel {
    constructor() {
        this.id = null,
            this.name = '',
            this.known_as = '',
            this.email = '',
            this.email_change = null,
            this.free_tier = false,
            this.description = '',
            this.country = {
                name: '',
                code: ''
            },
            this.country_code = '',
            this.city = '',
            this.avatar = {
                filename: '',
                public_url: ''
            },
            this.blocked_members = [],
            this.phone = '',
            this.address = '',
            this.company_name = '',
            this.company_phone = '',
            this.company_address = '',
            this.labels = [],
            this.images = [],
            this.roles = [],
            this.permissions = [],
            this.email_verified_at = null,
            this.last_visit = null,
            this.cooldown_ends = null,
            this.admin_verified = null,
            this.onboarding = null,
            this.email_notification_messages = null,
            this.tribe_subscription = null,
            this.project_subscriptions = null,
            this.created_at = null,
            this.updated_at = null,
            this.deleted_at = null;
    }
}
