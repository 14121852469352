import Api from './api';
import Store from '@/store';
export default {
    // GET - Get lodge or package destinations
    getDestinations(model_type) {
        return Api()
            .get(`/destinations?model_type=${model_type}`)
            .then((response) => response.data);
    },
    // Sort out the URL for the packages response & rest page
    setDestination(destination) {
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        return Api()
            .get(`/packages?per_page=${per_page}&destination=${destination}`)
            .then((response) => response.data);
    }
};
