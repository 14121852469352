import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import FooterNav from '@/components/Navigation/FooterNav.vue';
let Navigation = class Navigation extends Vue {
    constructor() {
        super(...arguments);
        this.showNav = false;
        this.showDropdown = null;
        this.dropdownOpened = null;
    }
    async mounted() {
        if (this.loggedIn && !this.profile) {
            await this.$store.dispatch('user/getUserProfile');
            await this.$store.dispatch('push_notifications/getPushNotifications');
        }
    }
    get isDesktop() {
        return this.mediaSize >= 2;
    }
    async toggle() {
        await this.$store.dispatch('app/setNav', !this.navOpen);
        if (this.$store.getters['auth/loggedIn']) {
            await this.$store.dispatch('push_notifications/getPushNotifications');
        }
    }
    async logout() {
        const response = await this.$store.dispatch('auth/logout');
        if (response == true) {
            this.$router.push({ name: 'login' });
        }
    }
    async goToSupportChat() {
        if (this.$route.params.slug != this.supportUser.id) {
            await this.$store.dispatch('conversations/setActiveConversationMember', this.supportUser);
            this.$router.push({ path: `/chat/${this.supportUser.id}` });
        }
    }
    async goToSafariExpertChat() {
        if (this.$route.params.slug != this.safariExpert.id) {
            await this.$store.dispatch('conversations/setActiveConversationMember', this.safariExpert);
            this.$router.push({ path: `/chat/${this.safariExpert.id}` });
        }
    }
    async openDropdown(key) {
        this.showDropdown = key;
    }
    async closeDropdown() {
        setTimeout(() => {
            this.showDropdown = null;
        }, 10000);
    }
    // Keep the dropdown opened when mouseover on the child
    async setIsDropdownOpened(key) {
        this.dropdownOpened = key;
    }
    async toggleDropdown(key) {
        if (this.showDropdown != null) {
            this.showDropdown = null;
            this.dropdownOpened = null;
        }
        else {
            this.showDropdown = key;
            this.dropdownOpened = key;
        }
    }
};
__decorate([
    Getter('getProfile', { namespace: 'user' })
], Navigation.prototype, "profile", void 0);
__decorate([
    Getter('loggedIn', { namespace: 'auth' })
], Navigation.prototype, "loggedIn", void 0);
__decorate([
    Getter('navOpen', { namespace: 'app' })
], Navigation.prototype, "navOpen", void 0);
__decorate([
    Getter('getSupportUser', { namespace: 'user' })
], Navigation.prototype, "supportUser", void 0);
__decorate([
    Getter('getSafariExpert', { namespace: 'user' })
], Navigation.prototype, "safariExpert", void 0);
__decorate([
    Getter('mediaSize', { namespace: 'app' })
], Navigation.prototype, "mediaSize", void 0);
Navigation = __decorate([
    Component({ components: { FooterNav } })
], Navigation);
export default Navigation;
