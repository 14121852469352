import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
// Modules
import { AppModule } from './modules/app';
import { AuthModule } from './modules/auth';
import { UserModule } from './modules/user';
import { NotificationModule } from './modules/notification';
import { LabelModule } from './modules/label';
import { LodgesModule } from './modules/lodges';
import { PackagesModule } from './modules/packages';
import { DestinationsModule } from './modules/destinations';
import { WishlistsModule } from './modules/wishlists';
import { QuestionsModule } from './modules/questions';
import { ProjectsModule } from './modules/projects';
import { DiscussionsModule } from './modules/discussions';
import { StoriesModule } from './modules/stories';
import { EngagementsModule } from './modules/engagements';
import { PaginationModule } from './modules/pagination';
import { MembersModule } from './modules/members';
import { PushNotificationsModule } from './modules/push_notifications';
import { ConversationsModule } from './modules/conversations';
Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});
export default new Vuex.Store({
    modules: {
        app: AppModule,
        auth: AuthModule,
        user: UserModule,
        notification: NotificationModule,
        label: LabelModule,
        lodges: LodgesModule,
        packages: PackagesModule,
        destinations: DestinationsModule,
        wishlists: WishlistsModule,
        questions: QuestionsModule,
        projects: ProjectsModule,
        discussions: DiscussionsModule,
        stories: StoriesModule,
        engagements: EngagementsModule,
        pagination: PaginationModule,
        members: MembersModule,
        push_notifications: PushNotificationsModule,
        conversations: ConversationsModule,
    },
    plugins: [vuexLocal.plugin],
});
