import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'landing-page',
        component: () => import(/* webpackChunkName: "landing" */ '@/views/LandingPage.vue'),
        beforeEnter: (to, from, next) => {
            const isLoggedIn = store.getters['auth/loggedIn'];
            const onboarding = store.getters['user/onboarding'];
            if (isLoggedIn) {
                if (!onboarding) {
                    next({ name: 'onboarding' });
                }
                else {
                    next({ name: 'my-village' });
                }
            }
            else {
                next();
            }
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth-login" */ '@/views/Auth/Login.vue'),
    },
    // Register a user into the system
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "auth-register" */ '@/views/Auth/Register/Register.vue'),
    },
    // Verify a users registration process
    {
        path: '/register/verify',
        name: 'verify-registration',
        component: () => import(/* webpackChunkName: "auth-verify" */ '@/views/Auth/Register/VerifyRegistration.vue'),
    },
    // Get the users password for resetting
    {
        path: '/forgot',
        name: 'forgot',
        component: () => import(/* webpackChunkName: "auth-forgot" */ '@/views/Auth/Password/ForgotPassword.vue'),
    },
    // Verify a users new password
    {
        path: '/forgot/verify',
        name: 'verify-password',
        component: () => import(/* webpackChunkName: "auth-reset" */ '@/views/Auth/Password/VerifyPassword.vue'),
    },
    {
        path: '/my-village',
        name: 'my-village',
        component: () => import(/* webpackChunkName: "my-village" */ "@/views/MyVillage/MyVillage.vue"),
        meta: {
            requiresAuth: false,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ "@/views/Profile/Profile.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/onboarding',
        name: 'onboarding',
        component: () => import(/* webpackChunkName: "onboarding" */ "@/views/Onboarding/Onboarding.vue"),
        // before enter check if user needs onboarding
        meta: {
            requiresAuth: true,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/subscribe',
        name: 'subscribe',
        component: () => import(/* webpackChunkName: "onboarding" */ "@/views/Subscribe/Subscribe.vue"),
        // before enter check if user needs subscribing
        meta: {
            requiresAuth: true,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/lodges',
        name: 'lodges',
        component: () => import(/* webpackChunkName: "lodges" */ "@/views/Lodges/Lodges.vue"),
        meta: {
            requiresAuth: false,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/lodge/:slug',
        name: 'lodge',
        component: () => import(/* webpackChunkName: "lodges" */ "@/views/Lodges/Lodge.vue"),
        meta: {
            requiresAuth: false,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/packages',
        name: 'packages',
        component: () => import(/* webpackChunkName: "packages" */ "@/views/Packages/Packages.vue"),
        meta: {
            requiresAuth: false,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/package/:slug',
        name: 'package',
        component: () => import(/* webpackChunkName: "packages" */ "@/views/Packages/Package.vue"),
        meta: {
            requiresAuth: false,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/special-offers',
        name: 'special-offers',
        component: () => import(/* webpackChunkName: "special-offers" */ "@/views/Packages/SpecialOffers.vue"),
        meta: {
            requiresAuth: false,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/wishlists',
        name: 'wishlists',
        component: () => import(/* webpackChunkName: "wishlists" */ "@/views/Wishlists/Wishlists.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/wishlist/:slug',
        name: 'wishlist',
        component: () => import(/* webpackChunkName: "wishlists" */ "@/views/Wishlists/Wishlist.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/questions',
        name: 'questions',
        component: () => import(/* webpackChunkName: "questions" */ "@/views/Questions/Questions.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/questions/thank-you',
        name: 'thank-you',
        component: () => import(/* webpackChunkName: "questions" */ "@/views/Questions/ThankYouPage.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/projects',
        name: 'projects',
        component: () => import(/* webpackChunkName: "projects" */ "@/views/Projects/Projects.vue"),
        meta: {
            requiresAuth: false,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/campaigns',
        name: 'campaigns',
        component: () => import(/* webpackChunkName: "campaigns" */ "@/views/Projects/Projects.vue"),
        meta: {
            requiresAuth: false,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/project/:slug',
        name: 'project',
        component: () => import(/* webpackChunkName: "projects" */ "@/views/Projects/Project.vue"),
        meta: {
            requiresAuth: false,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/campaign/:slug',
        name: 'campaign',
        component: () => import(/* webpackChunkName: "campaigns" */ "@/views/Projects/Project.vue"),
        meta: {
            requiresAuth: false,
            requiresOnboarding: false,
            requiresSubscription: false,
        },
    },
    {
        path: '/discussions',
        name: 'discussions',
        component: () => import(/* webpackChunkName: "discussions" */ "@/views/Discussions/Discussions.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/discussion/create',
        name: 'create-discussion',
        component: () => import(/* webpackChunkName: "discussions" */ "@/views/Discussions/CreateDiscussion.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/discussion/update',
        name: 'update-discussion',
        component: () => import(/* webpackChunkName: "discussions" */ "@/views/Discussions/UpdateDiscussion.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/discussion/:slug',
        name: 'discussion',
        component: () => import(/* webpackChunkName: "discussions" */ "@/views/Discussions/Discussion.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/stories',
        name: 'stories',
        component: () => import(/* webpackChunkName: "stories" */ "@/views/Stories/Stories.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/story/:slug',
        name: 'story',
        component: () => import(/* webpackChunkName: "stories" */ "@/views/Stories/Story.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/members',
        name: 'members',
        component: () => import(/* webpackChunkName: "members" */ "@/views/Members/Members.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/member/:slug',
        name: 'member',
        component: () => import(/* webpackChunkName: "members" */ "@/views/Members/Member.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/chats',
        name: 'chats',
        component: () => import(/* webpackChunkName: "conversations" */ "@/views/Conversations/Conversations.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/chat/:slug',
        name: 'chat',
        component: () => import(/* webpackChunkName: "conversations" */ "@/views/Conversations/Conversation.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import(/* webpackChunkName: "notifications" */ "@/views/PushNotifications/PushNotifications.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/stories/topic-search',
        name: 'stories/topic-search',
        component: () => import(/* webpackChunkName: "stories/topic-search" */ "@/views/TopicSearch/StoriesTopicSearch.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/discussions/topic-search',
        name: 'discussions/topic-search',
        component: () => import(/* webpackChunkName: "discussions/topic-search" */ "@/views/TopicSearch/DiscussionsTopicSearch.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
    {
        path: '/projects/topic-search',
        name: 'projects/topic-search',
        component: () => import(/* webpackChunkName: "projects/topic-search" */ "@/views/TopicSearch/ProjectsTopicSearch.vue"),
        meta: {
            requiresAuth: true,
            requiresOnboarding: true,
            requiresSubscription: true,
        },
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);
    const loggedIn = await store.getters['auth/loggedIn'];
    const userProfile = await store.getters['user/getProfile'];
    // Redirect if not logged in!
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!loggedIn) {
            // add the query path should the user be trying to access
            // a deep link
            next({ name: 'login', query: { redirect: to.fullPath } });
            return;
        }
    }
    // Redirect if not onboarded
    if (to.matched.some(record => record.meta.requiresOnboarding)) {
        if (!store.getters['user/onboarding']) {
            next({ name: 'onboarding' });
            return;
        }
    }
    // Redirect if not subscribed
    if (to.matched.some(record => record.meta.requiresSubscription)) {
        if (!userProfile.subscribed) {
            next({ name: 'subscribe' });
            return;
        }
    }
    next();
});
export default router;
