import { createNamespacedHelpers } from 'vuex';
import LodgeService from '@/services/lodges.ts';
import Store from '@/store';
// Inital state
const state = {
    AllLodges: [],
    getAllSearchResults: [],
    lodges: [],
    filter: '',
    searchTerm: '',
    page: 0,
    onlyFavourites: false,
    onlyNonMalaria: false,
    activeLodge: {
        id: '',
        representative_id: '',
        name: '',
        slug: '',
        location: '',
        lat: '',
        lng: '',
        excerpt: '',
        description: '',
        rate_disclaimer: '',
        discount_code: '',
        discount_description: '',
        enquiry_external_url: '',
        hero_image_id: '',
        hero_image_copyright: '',
        rates: [],
        images: [],
        offers: [],
        representative: null,
        social_links: [],
        related_links: [],
        favorite_lodges: [],
        wishlists: [],
        hero_image: null,
        deleted_at: '',
    },
};
const getters = {
    getAllLodges: (state) => state.allLodges,
    getAllSearchResults: (state) => state.AllSearchResults,
    getLodges: (state) => state.lodges,
    activeLodge: (state) => state.activeLodge,
    getFilter: (state) => state.filter,
    getSearchTerm: (state) => state.searchTerm,
    getPage: (state) => state.page,
    getOnlyFavourites: (state) => state.onlyFavourites,
    getOnlyNonMalaria: (state) => state.onlyNonMalaria
};
const mutations = {
    SET_EMPTY: (state) => {
        state.allLodges = [];
        state.getAllSearchResults = [];
        state.lodges = [];
    },
    SET_ALL_LODGES: (state, allLodges) => (state.allLodges = allLodges),
    SET_ALL_SEARCH_RESULTS: (state, AllSearchResults) => (state.AllSearchResults = AllSearchResults),
    SET_LODGES: (state, lodges) => (state.lodges = lodges),
    SET_ACTIVE_LODGE: (state, lodge) => (state.activeLodge = lodge),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_SEARCH_TERM: (state, searchTerm) => (state.searchTerm = searchTerm),
    SET_PAGE: (state, page) => (state.page = page),
    SET_ONLY_FAVOURITES: (state, onlyFavourites) => (state.onlyFavourites = onlyFavourites),
    SET_ONLY_NON_MALARIA: (state, onlyNonMalaria) => (state.onlyNonMalaria = onlyNonMalaria),
};
const actions = {
    async setFilter({ commit }, filter) {
        try {
            this.commit('lodges/SET_FILTER', filter);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setSearch({ commit }, search) {
        try {
            this.commit('lodges/SET_SEARCH_TERM', search);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setPage({ commit }, page) {
        try {
            this.commit('lodges/SET_PAGE', page);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setOnlyFavourites({ commit }, onlyFavourites) {
        try {
            this.commit('lodges/SET_ONLY_FAVOURITES', onlyFavourites);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async getAllLodges() {
        try {
            const response = await LodgeService.getAll();
            this.commit('lodges/SET_ALL_LODGES', response.data.lodges);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getAllSearchResults() {
        try {
            const response = await LodgeService.getAllSearch();
            this.commit('lodges/SET_ALL_SEARCH_RESULTS', response.data.lodges);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
        }
    },
    async setOnlyNonMalaria({ commit }, onlyNonMalaria) {
        try {
            this.commit('lodges/SET_ONLY_NON_MALARIA', onlyNonMalaria);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    // Takes the currently set values for search, filter and pagination
    // and returns a list of lodges for display
    // BEFORE calling this function be sure to set the filter,search and page values
    async getLodges() {
        try {
            const response = await LodgeService.get();
            this.commit('lodges/SET_LODGES', response.data.lodges);
            this.dispatch('pagination/setPagination', response.data.pagination);
            this.dispatch('user/resetSingleResourceCount', 'lodges');
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getLodgeBySlug({ commit, dispatch, getters }, slug) {
        try {
            const response = await LodgeService.getBySlug(slug);
            this.commit('lodges/SET_ACTIVE_LODGE', response.data.lodge);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setActiveLodge({ commit, dispatch, getters }, lodge) {
        try {
            this.commit('lodges/SET_ACTIVE_LODGE', lodge);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async requestLodgeInformation({}, data) {
        try {
            const response = await LodgeService.requestInformation(data);
            await this.dispatch('notification/showSuccessModal', response.message);
        }
        catch (e) {
            // await this.dispatch('notification/showError', e.response.data.message)
            throw e.response.data;
        }
    },
    async favourite({}, slug) {
        try {
            const response = await LodgeService.favourite(slug);
            await Store.dispatch('lodges/setActiveLodge', response.data.lodge);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data.lodge;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('LodgesModule');
export const LodgesModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
