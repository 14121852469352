import Api from './api';
export default {
    // Hit the login route and get the users details
    login(params) {
        return Api()
            .post('auth/login', params)
            .then((response) => response.data);
    },
    // Hit the reset request route passing along the users email
    requestReset(email) {
        return Api()
            .post('auth/forgot', { email })
            .then((response) => response.data);
    },
    // update the users password with token
    updatePassword(params) {
        return Api()
            .post('auth/reset', params)
            .then((response) => response.data);
    },
    // account creation / register
    register(account) {
        return Api()
            .post(`auth/register`, account)
            .then((response) => response.data);
    },
    // Hit the send verify token route passing along the users email
    sendVerifyToken(payload) {
        return Api()
            .post(`auth/send-verify-token`, payload)
            .then((response) => response.data);
    },
    verifyUser(data) {
        return Api()
            .post(`auth/verify`, data)
            .then((response) => response.data);
    },
    me() {
        return Api()
            .get('profile')
            .then((response) => response.data);
    },
};
