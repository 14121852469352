import Api from './api';
import Store from '@/store';
export default {
    // GET
    get() {
        // Sort out whats in the store
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        const current_page = Store.getters['packages/getPage'] ? Store.getters['packages/getPage'] : 1;
        const filter = Store.getters['packages/getFilter'] ? Store.getters['packages/getFilter'] : null;
        const onlyFavourites = Store.getters['packages/getOnlyFavourites'] ? Store.getters['packages/getOnlyFavourites'] : null;
        const onlyNonMalaria = Store.getters['packages/getOnlyNonMalaria'] ? Store.getters['packages/getOnlyNonMalaria'] : null;
        const onlySpecialOffers = Store.getters['packages/getOnlySpecialOffers'] ? Store.getters['packages/getOnlySpecialOffers'] : null;
        const selectedDestination = Store.getters['destinations/selectedDestination'] ? Store.getters['destinations/selectedDestination'] : null;
        const selectedExperience = Store.getters['packages/selectedExperience'] ? Store.getters['packages/selectedExperience'] : null;
        // Sort out the URL for the packages response
        let url = `/packages?per_page=${per_page}`;
        if (current_page) {
            url += `&page=${current_page}`;
        }
        if (filter && filter != '') {
            url += `&label=${filter}`;
        }
        if (onlyFavourites && onlyFavourites === true) {
            url += `&favorite=true`;
        }
        if (onlyNonMalaria && onlyNonMalaria === true) {
            url += `&non_malaria=true`;
        }
        if (onlySpecialOffers && onlySpecialOffers === true) {
            url += `&special_offer=true`;
        }
        if (selectedDestination && selectedDestination != '') {
            url += `&destination=${selectedDestination}`;
        }
        if (selectedExperience && selectedExperience != '') {
            url += `&experience=${selectedExperience}`;
        }
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    getBySlug(slug) {
        return Api()
            .get(`/packages/${slug}`)
            .then((response) => response.data);
    },
    paginate(page) {
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        return Api()
            .get(`/packages?page=${page}&per_page=${per_page}`)
            .then((response) => response.data);
    },
    requestInformation(requestData) {
        return Api()
            .post(`packages/request-info/${requestData.slug}`, { name: requestData.name, email: requestData.email, comment: requestData.comment, recaptchaToken: requestData.recaptchaToken })
            .then((response) => response.data);
    },
    favourite(slug) {
        return Api()
            .post(`/packages/${slug}/favorite`)
            .then((response) => response.data);
    },
    // GET WISHLIST PACKAGES
    getWishlistPackages(wishlist_slug) {
        // Sort out whats in the store
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        const current_page = Store.getters['packages/getPage'] ? Store.getters['packages/getPage'] : 1;
        const search_term = Store.getters['packages/getSearchTerm'] ? Store.getters['packages/getSearchTerm'] : null;
        const filter = Store.getters['packages/getFilter'] ? Store.getters['packages/getFilter'] : null;
        const onlyFavourites = Store.getters['packages/getOnlyFavourites'] ? Store.getters['packages/getOnlyFavourites'] : null;
        const onlyNonMalaria = Store.getters['packages/getOnlyNonMalaria'] ? Store.getters['packages/getOnlyNonMalaria'] : null;
        // Sort out the URL for the packages response
        let url = `/wishlists/${wishlist_slug}/get-wishlist-Items?item_type=package&per_page=${per_page}`;
        if (current_page) {
            url += `&page=${current_page}`;
        }
        if (search_term && search_term != '') {
            url += `&q=${search_term}`;
        }
        if (filter && filter != '') {
            url += `&label=${filter}`;
        }
        if (onlyFavourites && onlyFavourites === true) {
            url += `&favorite=true`;
        }
        if (onlyNonMalaria && onlyNonMalaria === true) {
            url += `&non_malaria=true`;
        }
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    // Sort out the URL for the packages response & rest page
    setExperience(experience) {
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        return Api()
            .get(`/packages?per_page=${per_page}&experience=${experience}`)
            .then((response) => response.data);
    },
    // GET - Get all experiences
    getExperiences() {
        return Api()
            .get('/experiences')
            .then((response) => response.data);
    }
};
