import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Btn = class Btn extends Vue {
    mounted() { }
    click() {
        if (!this.disabled) {
            this.$emit('click');
        }
    }
};
__decorate([
    Prop()
], Btn.prototype, "label", void 0);
__decorate([
    Prop({ default: '' })
], Btn.prototype, "icon", void 0);
__decorate([
    Prop({ default: true })
], Btn.prototype, "primary", void 0);
__decorate([
    Prop({ default: false })
], Btn.prototype, "disabled", void 0);
__decorate([
    Prop({ default: 'medium' })
], Btn.prototype, "size", void 0);
Btn = __decorate([
    Component
], Btn);
export default Btn;
