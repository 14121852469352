import { createNamespacedHelpers } from 'vuex';
import QuestionService from '@/services/questions.ts';
// Inital state
const state = {
    questions: [],
};
const getters = {
    getQuestions: (state) => state.questions,
};
const mutations = {
    SET_EMPTY: (state) => (state.questions = []),
    SET_QUESTIONS: (state, questions) => (state.questions = questions),
};
const actions = {
    async getQuestions() {
        try {
            const response = await QuestionService.get();
            this.commit('questions/SET_QUESTIONS', response.data.questions);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async submitQuestions({}, formData) {
        try {
            const response = await QuestionService.submitQuestions(formData);
            await this.dispatch('notification/showSuccess', { message: response.message, isSticky: true });
            return response.data.wishlist;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('QuestionsModule');
export const QuestionsModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
