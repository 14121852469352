export default {
    landing: {
        register: 'Register',
        login: 'Login',
        logout: 'Logout',
        guest: 'Guest',
    },
    auth: {
        reset_password_heading: 'Reset your password',
        send_reset_token: 'Send me a reset token',
        or: 'Or',
        guest_access: 'Continue as Guest',
        back_to_login: 'Back to login',
        reset_password: 'Reset your password',
        no_account: `Don't have an account`,
        forgot_password: 'Forgot your password?',
        verify_registration: 'Verify your account'
    },
    form: {
        button: {
            login: 'Login',
            register: 'Register',
            save: 'Save',
            cancel: 'Cancel',
            verify: 'Verify',
            update: 'Update',
            edit: 'Edit',
            submit: 'Submit',
            next: 'Next',
            upload: 'Upload',
            report: 'Report',
            agree: 'I agree',
            pledge: 'Pledge',
            donate: 'Donate',
        },
        register: {
            verify_email: 'Verify my email',
            complete: 'Join the club'
        },
        token: {
            label: 'Reset token',
            placeholder: 'The reset token',
        },
        name: {
            label: 'Name',
            placeholder: 'Leeroy Jenkins',
        },
        language: {
            label: 'Language',
        },
        email: {
            label: 'Email',
            placeholder: 'you@email.com',
        },
        quantity: {
            label: 'Amount in USD',
            placeholder: '1',
        },
        password: {
            label: 'Password',
            placeholder: '',
            confirm_password: 'Confirm Password'
        },
        register_token: {
            label: 'Verification code',
            placeholder: '123456',
        },
        resend_token: 'Resend verification code',
        labels: {
            label: `Topics`,
            placeholder: '',
            empty: 'No Topics selected',
        },
        users: {
            label: 'Users',
            placeholder: '',
            empty: 'No users selected',
        },
        profile: {
            edit: 'Edit My Profile',
            save: 'Save My Profile',
            name: 'Name',
            known_as: 'Known as',
            email: 'Email',
            description: 'Tell us about yourself',
            country: 'Country',
            city: 'Town / City',
            phone: 'Phone number',
            address: 'Address',
            company_name: 'Company Name',
            company_phone: 'Company Phone',
            company_address: 'Company Address',
            email_notification_messages: 'Send me messages by email',
            change_password: 'Change password',
            current_password: 'Your current password',
            new_password: 'Your new password',
            confirm_password: 'Confirm your new password',
            delete_account: 'Delete Account',
        },
        discussion: {
            create_discussion: 'Create topic for discussion',
            title: 'Title',
            discussion: 'Discussion',
            labels: 'Tag as',
            submit: 'Create',
            submit_restricted_words: 'submit restricted words',
            update: 'Update',
            update_discussion: 'Update discussion',
            reply: 'Comment',
        },
        comment: {
            placeholder: 'Type Comment',
        },
        message: {
            placeholder: 'Type your message here',
        },
        report: {
            placeholder: 'Reason for report'
        }
    },
    search: {
        sort_by: 'Sort by',
        no_results: 'No results',
        search: 'Search',
        show_more: 'Show More',
        show_less: 'Show Less',
    },
    filter: {
        filter: 'Filters',
        filters: 'Filters',
        filter_by_topic: 'Filter by topic',
        currency: 'Currency selector'
    },
    base: {
        yes: 'Yes',
        no: 'No',
        true: 'True',
        false: 'False',
        currency: '$',
        share: 'Share',
        download: 'Download',
        topics: 'Tagged as',
        posted_by: 'Posted by',
        posted_at: 'Posted at',
        published_at: 'Published',
        published_on: 'Published on',
        updated_at: 'Last updated',
        reply: 'reply',
        replied: 'Replied',
        post_comment: 'Post comment',
        update_comment: 'Update comment',
        recommend: 'Recommend',
        search: 'Search',
        create: 'Create',
        comment: 'Comment',
        edit: 'Edit',
        delete: 'Delete',
        user_edited: 'user edited',
        admin_edited: 'admin edited',
        admin_and_user_edited: 'admin and user edited',
        deleted: 'deleted',
        send: 'Send',
        cancel: 'Cancel',
        loading: 'Loading',
        support: 'Support',
        general: 'General',
        travel_desk: 'Travel desk',
        is_travel_related: 'Travel'
    },
    discussion: {
        discussion: 'Discussion',
        discussions: 'Forum',
        search: 'Title or subject matter',
        create: 'Create topic for discussion',
        edit: 'Edit Discussion',
        delete: 'Delete Discussion',
        view_discussions: 'View Forum',
        back_to_discussions: 'Back to Forum',
        member_share_intro: 'Search and select the member below to share this discussion with them through a private chat message.',
        member_share_message: 'Check out this great discussion I found!',
    },
    conversation: {
        conversation: 'Conversation',
        conversations: 'Conversations',
        delete_confirmation: 'Are you sure you want to delete this chat?',
        chat: 'Chat',
        chats: 'Inbox',
        back_to_chats: 'Back to Inbox',
        no_chats: 'You have no active chats',
        chat_with: 'Chatting to',
        private_message: 'Send a message',
        no_messages: 'No messages',
        refreshing: 'Refreshing',
        refresh_chat: 'Refresh Chat'
    },
    lodge: {
        lodge: 'Lodge',
        lodges: 'Lodges',
        travel: 'Travel with us',
        representative: 'Lodge Representative',
        start_discussion: 'Start the discussion:',
        related_links: 'Additional information',
        indication_rates: 'Indication rates',
        no_indication_rates: 'No indication rates',
        additional_indication_rates: 'Additional indication rates',
        download_voucher: 'Download Discount Voucher',
        request_contact: 'Make an enquiry',
        request_contact_message_name: 'Enter your name',
        request_contact_message_email: 'Enter your email address',
        request_contact_message_title: 'Add a comment for our team',
        request_contact_message_button: 'Submit enquiry',
        request_contact_recaptcha_button: 'Recaptcha',
        search: 'Lodge name or place',
        view_lodge: 'View Lodge',
        view_lodges: 'View Lodges',
        member_share_intro: 'Search and select the member below to share this lodge with them through a private chat message.',
        member_share_message: 'Check out this great lodge I found!'
    },
    special_offer: {
        special_offer: 'Special offer',
        special_offers: 'Special offers',
        no_results: 'We have no special offers at the moment, please check back again soon.'
    },
    package: {
        package: 'Package',
        packages: 'Packages',
        travel: 'Travel with us',
        representative: 'Package Representative',
        start_discussion: 'Start the discussion:',
        related_links: 'Additional information',
        indication_rates: 'Indication rates',
        no_indication_rates: 'No indication rates',
        additional_indication_rates: 'Additional indication rates',
        download_voucher: 'Download Discount Voucher',
        request_contact: 'Make an enquiry',
        request_contact_message_title: 'Add a comment for our team',
        request_contact_message_button: 'Submit enquiry',
        search: 'Package name or place',
        view_package: 'View Package',
        view_packages: 'View Packages',
        member_share_intro: 'Search and select the member below to share this lodge with them through a private chat message.',
        member_share_message: 'Check out this great lodge I found!'
    },
    wishlist: {
        wishlist: 'Wishlist',
        wishlists: 'My wishlists',
        create: 'Create new wishlist',
        delete_confirmation: 'Are you sure you want to delete this wishlist?',
        add_to_wishlist: 'Add to wishlist',
        remove_lodge: 'Are you sure you want to remove this lodge from the wishlist?',
        remove_package: 'Are you sure you want to remove this package from the wishlist?',
        add_remove_from_wishlist: 'Add/Remove from wishlist',
        send_to_desk: 'Send to travel desk',
        other_wishlists: 'Back'
    },
    question: {
        description: 'Before we build the perfect itinerary for you, we\'d like to ask you a few questions.',
        back: 'Back',
        thanks_title: 'Thank you for enquiring',
        thanks_message: 'A safari expert will be in touch with you shortly.',
    },
    project: {
        project: 'Campaign',
        projects: 'Campaigns',
        donate: 'Donate',
        more_info: 'More details',
        cancel_donate: 'Cancel Monthly Donation',
        search: 'Campaign name, species or activity',
        files: 'Reports and financials',
        relevant_links: 'Additional information',
        topics: 'Tagged as',
        highlights: 'Campaign Highlights',
        view_projects: 'View campaigns',
        member_share_intro: 'Search and select the member below to share this campaigns with them through a private chat message.',
        member_share_message: 'Check out this great campaign I found!'
    },
    story: {
        story: 'Story',
        stories: 'Stories',
        search: 'Story title or subject matter',
        view_stories: 'View Stories',
        member_share_intro: 'Search and select the member below to share this story with them through a private chat message.',
        member_share_message: 'Check out this great story I found!',
        reading_time: 'Reading time',
    },
    topic_search_results: {
        title: 'Search Results',
    },
    engagements: {
        favourite: 'Mark as favourite',
        unfavourite: 'Remove from favourites',
        agree: 'Agree',
        disagree: 'Disagree',
        report: 'Report as inappropriate',
        reported: 'Reported',
        share_member: 'Share with member',
    },
    comments: {
        comment: 'Comment',
        comments: 'Comments',
        show_replies: 'Show replies',
        hide_replies: 'Hide Replies',
        commented: 'Commented',
        replied: 'Replied',
        no_comments: 'No Comments',
        add_comment: 'Add Comment',
        edit_comment: 'Edit Comment',
        has_restricted_words: 'You have restricted words within your comment',
        submit_restricted_words: 'submit with restricted words',
        admin_approval_required: 'Comments with restricted words require approval. You will receive an email once approved.'
    },
    profile: {
        my_topics: 'My Topics',
        my_details: 'My Details',
        address: 'Address',
        works_for: 'Works for',
        known_as: 'Known as',
        no_description: 'No information has been added',
        no_images: 'No images added to your profile',
        upload_images: 'Upload images',
        member_share_intro: 'Search and select the member below to share your profile with them through a private chat message.',
        member_share_message: 'Check out my profile',
        change_password: 'Change your password'
    },
    myvillage: {
        my_village: 'My Village',
    },
    member: {
        member: 'Member',
        members: 'Members',
        view_members: 'View Members',
        topics: 'Topics of Interest',
        view_profile: 'View Profile',
        block_member: 'Block this member',
        unblock_member: 'Unblock this member',
        member_share_intro: 'Search and select the member below to share this member with them through a private chat message.',
        member_share_message: 'Check out this members profile',
        search_other_members: 'Search for other members by name in the search bar above'
    },
    inbox: {
        inbox: 'Inbox'
    },
    social: {
        no_social_links: 'No social Links',
        social_links: 'Links',
        share_title: 'Africa Geographic',
        share_description: 'Join Africa Geographic club to access to premium stories, discounts on lodges and more.',
        share_quote: 'Celebrate Africa & do good',
        share_hashtags: 'africa,club,africageographic',
        share_twitter_user: 'africageo',
    },
    push_notifications: {
        notifications: 'Notifications',
        no_notifications: 'No notifications',
        delete_confirmation: 'Are you sure you want to delete this notification?',
        read_at: 'Read at',
        mark_read: 'Mark read',
        mark_unread: 'Mark unread',
        click_here: 'Click here to view',
    },
    representative: 'Club Representative',
    footer: {
        privacy_policy: 'Privacy policy',
        rules_of_engagement: 'Rules of engagement',
        manifesto: 'Africa Geographic manifesto',
    },
    images: {
        upload_requirements: 'Images should be .jpeg or .jpg format and a maximum size of 20mb each',
        pending: 'Pending upload',
        uploaded: 'Uploaded images'
    },
    payments: {
        cancel: 'Cancel',
        donation_title: 'Send a once off donation to support this projects goals.',
        donation_reason: 'What\'s the reason for your donation?',
        donation_add_payment_method: 'Add New Payment Method &raquo;',
        donation_existing_payment_method: '&laquo; Select Existing Payment Method',
        donation_next_text: 'donate now',
    }
};
