import { createNamespacedHelpers } from 'vuex';
// Inital state
const state = {
    id: null,
    status: null,
    message: null,
    sticky: true,
    display: false,
    position: 'top',
    guest: false,
};
const getters = {
    id: (state) => state.id,
    message: (state) => state.message,
    status: (state) => state.status,
    sticky: (state) => state.sticky,
    display: (state) => state.display,
    position: (state) => state.position,
    guest: (state) => state.guest,
    notificiation: (state) => state,
};
const mutations = {
    UPDATE_NOTIFICATION: (state, notification) => {
        state.id = notification.id;
        state.status = notification.status;
        state.message = notification.message;
        state.sticky = notification.sticky;
        state.display = notification.display;
        state.position = notification.position;
        state.guest = notification.guest;
    },
    SHOW_NOTIFICATION: (state) => (state.display = true),
    HIDE_NOTIFICATION: (state) => (state.display = false),
};
const actions = {
    async showNotification({ commit, dispatch }, notification) {
        this.commit('notification/UPDATE_NOTIFICATION', notification);
        this.commit('notification/SHOW_NOTIFICATION');
        if (notification.sticky == false) {
            setTimeout(() => {
                commit('HIDE_NOTIFICATION', false);
            }, 3000);
        }
    },
    async showSuccess({ commit, dispatch }, message) {
        let msg = "";
        let sticky = false;
        if (typeof message === "string") {
            msg = message;
        }
        if (typeof message === "object") {
            msg = message.message;
            sticky = message.isSticky;
        }
        this.dispatch('notification/showNotification', {
            id: 'null',
            status: 'success',
            message: msg,
            sticky: sticky,
            display: true,
            position: 'top',
            guest: false,
        }, {
            root: true,
        });
    },
    async showSuccessModal({ commit, dispatch }, message) {
        this.dispatch('notification/showNotification', {
            id: 'null',
            status: 'success',
            message: message,
            sticky: true,
            display: true,
            position: 'center',
            guest: false,
        }, {
            root: true,
        });
    },
    async showError({ commit, dispatch }, message) {
        this.dispatch('notification/showNotification', {
            id: 'null',
            status: 'error',
            message: message,
            sticky: true,
            display: true,
            position: 'top',
            guest: false,
        }, {
            root: true,
        });
    },
    async showErrorModal({ commit, dispatch }, message) {
        this.dispatch('notification/showNotification', {
            id: 'null',
            status: 'error',
            message: message,
            sticky: true,
            display: true,
            position: 'center',
            guest: false,
        }, {
            root: true,
        });
    },
    async showErrorModalGuest({ commit, dispatch }, message) {
        this.dispatch('notification/showNotification', {
            id: 'null',
            status: 'error',
            message: message,
            sticky: true,
            display: true,
            position: 'center',
            guest: true,
        }, {
            root: true,
        });
    },
    hideNotification({ commit, dispatch }, notification) {
        commit('HIDE_NOTIFICATION', false);
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('NotificationModule');
export const NotificationModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
