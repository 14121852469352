import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import store from '@/store';
import { Getter } from 'vuex-class';
// components
import Navigation from '@/components/Navigation/Navigation.vue';
import Notification from '@/components/Notification/Notification.vue';
import FooterNav from '@/components/Navigation/FooterNav.vue';
// Services
import PushService from '@/services/push_notifications';
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.enterAnimation = '';
        this.leaveAnimation = '';
        this.localSize = 0;
    }
    onRouteChange(oldValue, newValue) {
        this.enterAnimation = newValue.meta.transitionEnter;
        this.leaveAnimation = oldValue.meta.transitionLeave;
        this.$store.dispatch('app/cleanLocalStorage');
        this.$store.dispatch('app/setNav', false);
        if (this.$route.query.push && this.$route.query.push !== "") {
            let id = this.$route.query.push;
            PushService.trackCustomNotification(id);
        }
    }
    async mounted() {
        if (store.getters['auth/loggedIn']) {
            const profile = store.getters['user/getProfile'];
            if (!profile) {
                await store.dispatch('user/getUserProfile');
            }
        }
        this.$nextTick(() => {
            store.dispatch('app/calcBreakPoint', window.innerWidth);
            window.addEventListener('resize', this.onResize);
        });
        let self = this;
        // DO NOT REMOVE: THIS IS USED TO DEBUG LOCAL STORAGE SIZE ISSUES
        // setInterval(function (){
        //     var _lsTotal = 0, _xLen, _x;
        //     for (_x in localStorage) {
        //         if (!localStorage.hasOwnProperty(_x)) {
        //             continue;
        //         }
        //         _xLen = ((localStorage[_x].length + _x.length) * 2);
        //         _lsTotal += _xLen;
        //     };
        //     self.localSize = (_lsTotal / 1024).toFixed(2) + " KB";
        // }, 500);
    }
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    }
    onResize() {
        store.dispatch('app/calcBreakPoint', window.innerWidth);
    }
    backToTop() {
        window.scrollTo({
            top: 0,
            left: 100,
            behavior: 'smooth',
        });
    }
};
__decorate([
    Getter('position', { namespace: 'notification' })
], App.prototype, "notificationPosition", void 0);
__decorate([
    Getter('display', { namespace: 'notification' })
], App.prototype, "notificationDisplay", void 0);
__decorate([
    Watch('$route')
], App.prototype, "onRouteChange", null);
App = __decorate([
    Component({ components: { Notification, Navigation, FooterNav } })
], App);
export default App;
