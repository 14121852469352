import { createNamespacedHelpers } from 'vuex';
// Inital state
const state = {
    pagination: {
        current_page: 1,
        from: 0,
        last_page: 1,
        per_page: 6,
        to: 0,
        total: 0
    },
    per_page_count: 6
};
const getters = {
    pagination: (state) => state.pagination,
    per_page_count: (state) => state.per_page_count,
};
const mutations = {
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    SET_PER_PAGE_COUNT: (state, per_page_count) => (state.per_page_count = per_page_count)
};
const actions = {
    async setPagination({}, payload) {
        try {
            this.commit('pagination/SET_PAGINATION', payload);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setPerPageCount({}, payload) {
        try {
            this.commit('pagination/SET_PER_PAGE_COUNT', payload);
        }
        catch (e) {
            throw e.response.data;
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('PaginationModule');
export const PaginationModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
