import Api from './api';
import Store from '../store';
export default {
    // GET
    get() {
        // Sort out whats in the store
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        const current_page = Store.getters['discussions/getPage'] ? Store.getters['discussions/getPage'] : 1;
        const search_term = Store.getters['discussions/getSearchTerm'] ? Store.getters['discussions/getSearchTerm'] : null;
        const filter = Store.getters['discussions/getFilter'] ? Store.getters['discussions/getFilter'] : null;
        const onlyFavourites = Store.getters['discussions/getOnlyFavourites'] ? Store.getters['discussions/getOnlyFavourites'] : null;
        // Sort out the URL for the discussions response
        let url = `/discussions?per_page=${per_page}`;
        if (current_page) {
            url += `&page=${current_page}`;
        }
        if (search_term && search_term != '') {
            url += `&q=${search_term}`;
        }
        if (filter && filter != '') {
            url += `&label=${filter}`;
        }
        if (onlyFavourites && onlyFavourites === true) {
            url += `&favorite=true`;
        }
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    getBySlug(slug) {
        return Api()
            .get(`/discussions/${slug}`)
            .then((response) => response.data);
    },
    // POST
    create(formData) {
        return Api()
            .post('/discussions', formData, {
            headers: {
                Accept: 'application/json',
                withCredentials: true,
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${Store.state.auth.token}`,
            },
        })
            .then((response) => response.data);
    },
    update(formData, slug) {
        return Api()
            .post(`/discussions/${slug}`, formData, {
            headers: {
                Accept: 'application/json',
                withCredentials: true,
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${Store.state.auth.token}`,
            },
        })
            .then((response) => response.data);
    },
    delete(slug) {
        return Api()
            .delete(`/discussions/${slug}`)
            .then((response) => response.data);
    },
    favourite(slug) {
        return Api()
            .post(`/discussions/${slug}/favorite`)
            .then((response) => response.data);
    },
};
