import { createNamespacedHelpers } from 'vuex';
import WishlistService from '@/services/wishlists.ts';
// Inital state
const state = {
    wishlists: [],
    getModalWishlists: [],
    getWishlistLodges: [],
    getWishlistPackages: [],
    filter: '',
    searchTerm: '',
    page: 0,
    activeWishlist: {
        id: '',
        user_id: '',
        name: '',
        slug: '',
        user: null,
        lodges: [],
        Discussions: [],
        Stories: [],
        Projects: [],
    },
};
const getters = {
    getWishlists: (state) => state.wishlists,
    getModalWishlists: (state) => state.getModalWishlists,
    activeWishlist: (state) => state.activeWishlist,
    getWishlistLodges: (state) => state.getWishlistLodges,
    getWishlistPackages: (state) => state.getWishlistPackages,
    getFilter: (state) => state.filter,
    getSearchTerm: (state) => state.searchTerm,
    getPage: (state) => state.page,
};
const mutations = {
    SET_EMPTY: (state) => {
        state.wishlists = [];
        state.getWishlistLodges = [];
        state.getWishlistPackages = [];
    },
    SET_WISHLISTS: (state, wishlists) => (state.wishlists = wishlists),
    SET_MODAL_WISHLISTS: (state, modalWishlists) => (state.getModalWishlists = modalWishlists),
    SET_ACTIVE_WISHLIST: (state, wishlist) => (state.activeWishlist = wishlist),
    SET_WISHLIST_LODGES: (state, lodges) => (state.getWishlistLodges = lodges),
    SET_WISHLIST_PACKAGES: (state, packages) => (state.getWishlistPackages = packages),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_SEARCH_TERM: (state, searchTerm) => (state.searchTerm = searchTerm),
    SET_PAGE: (state, page) => (state.page = page),
};
const actions = {
    async setFilter({ commit }, filter) {
        try {
            this.commit('wishlists/SET_FILTER', filter);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setSearch({ commit }, search) {
        try {
            this.commit('wishlists/SET_SEARCH_TERM', search);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setPage({ commit }, page) {
        try {
            this.commit('wishlists/SET_PAGE', page);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    // Takes the currently set values for search, filter and pagination
    // and returns a list of wishlist for display
    // BEFORE calling this function be sure to set the filter,search and page values
    async getWishlists() {
        try {
            const response = await WishlistService.get();
            this.commit('wishlists/SET_WISHLISTS', response.data.wishlists);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getModalWishlists() {
        try {
            const response = await WishlistService.getModalWishlists();
            this.commit('wishlists/SET_MODAL_WISHLISTS', response.data.wishlists);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getWishlistsBySlug({}, slug) {
        try {
            const response = await WishlistService.getBySlug(slug);
            this.commit('wishlists/SET_WISHLIST', response.data.wishlist);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setActiveWishlist({ commit, dispatch, getters }, wishlist) {
        try {
            this.commit('wishlists/SET_ACTIVE_WISHLIST', wishlist);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getWishlistLodges({}, slug) {
        try {
            const response = await WishlistService.getWishlistLodges(slug);
            this.commit('wishlists/SET_WISHLIST_LODGES', response.data.lodges);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
        }
    },
    async getWishlistPackages({}, slug) {
        try {
            const response = await WishlistService.getWishlistPackages(slug);
            this.commit('wishlists/SET_WISHLIST_PACKAGES', response.data.packages);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
        }
    },
    async create({}, formData) {
        try {
            const response = await WishlistService.create(formData);
            await this.dispatch('notification/showSuccess', { message: response.message, isSticky: true });
            return response.data.wishlist;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async update({}, payload) {
        try {
            const response = await WishlistService.update(payload.formData, payload.slug);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data.wishlist;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async addOrRemoveItem({}, formData) {
        try {
            const response = await WishlistService.addOrRemoveItem(formData);
            await this.dispatch('notification/showSuccess', { message: response.message, isSticky: true });
            return response.data.wishlist;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async delete({}, slug) {
        try {
            const response = await WishlistService.delete(slug);
            await this.dispatch('notification/showSuccess', response.message);
            return;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    }
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('WishlistsModule');
export const WishlistsModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
