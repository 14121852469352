import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import VueMeta from "vue-meta";
import router from "./router";
import store from "./store";
import { i18n } from '@/i18n';
import * as VueGoogleMaps from 'vue2-google-maps';
import { VueReCaptcha } from "vue-recaptcha-v3";
// For more options see below
Vue.use(VueReCaptcha, {
    siteKey: "6LcM4XAiAAAAAKlX116pdRYd3UhIQ4XYftmVbLkm",
    loaderOptions: {
        autoHideBadge: true
    },
});
// @ts-ignore
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import dayjs from 'dayjs';
Vue.prototype.dayjs = dayjs;
Vue.config.productionTip = false;
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true,
});
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyClGC1u6gUMuyfa1GYH4mljUHYoRZpjAHA',
        libraries: 'places',
    },
});
Vue.component('GmapCluster', GmapCluster);
// @ts-ignore
import VueCurrencyInput from 'vue-currency-input';
const pluginOptions = {
    /* see config reference */
    globalOptions: { currency: 'USD', locale: 'en-US', distractionFree: false, }
};
Vue.use(VueCurrencyInput, pluginOptions);
import Permissions from '@/directives/Permissions';
Vue.directive('can', Permissions); // used as v-can="'lodge.edit'"
// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle, faCheck, faCheckCircle, faCircle, faTimesCircle, faTimes, faPlus, faPlusCircle, faMinus, faMinusCircle, faChevronLeft, faChevronRight, faBars, faPlay, faTrashAlt, faExternalLink, faDownload, faLink, faEnvelope, faSearch, faSpinnerThird, faGlobe, faExpandAlt, faEnvelopeOpenText, faCommentsAlt, faCommentAlt, faStar, faHeart, faCommentAltExclamation, faShareAlt, faEdit, faPaperPlane, faChevronUp, faChevronDown, faThumbsUp as faThumbsUpLight, faThumbsDown as faThumbsDownLight, faEllipsisV, faFilter, faUnlockAlt, faSyncAlt, faEyeSlash, faEye, faCommentCheck, faList, faGlobeAfrica, faCommentMinus, faComment, faClock, faRedo, faSlidersH } from '@fortawesome/pro-light-svg-icons';
import { faReply, faThumbsUp, faThumbsDown, faStar as faStarSolid, faHeart as faHeartSolid, faList as faListSolid, faGlobeAfrica as faGlobeAfricaSolid, faCircle as faCircleSolid, faTimes as faTimesSolid, faCheck as faCheckSolid, faSlidersH as faSlidersHSolid, faRedo as faRedoSolid, } from '@fortawesome/pro-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin, faTelegramPlane, faTwitter, faWhatsapp, faYoutube, faPinterest, faVimeo, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faExclamationCircle, faCheck, faCheckSolid, faCheckCircle, faCircle, faCircleSolid, faTimesCircle, faTimes, faTimesSolid, faPlus, faPlusCircle, faMinus, faMinusCircle, faChevronLeft, faChevronRight, faBars, faPlay, faTrashAlt, faExternalLink, faDownload, faLink, faFacebook, faTwitter, faEnvelope, faYoutube, faLinkedin, faWhatsapp, faTelegramPlane, faSearch, faSpinnerThird, faGlobe, faInstagram, faExpandAlt, faExclamationCircle, faEnvelopeOpenText, faThumbsUp, faThumbsDown, faCommentsAlt, faCommentAlt, faStar, faStarSolid, faHeart, faHeartSolid, faCommentAltExclamation, faShareAlt, faEdit, faPaperPlane, faReply, faChevronUp, faChevronDown, faThumbsUpLight, faThumbsDownLight, faEllipsisV, faFilter, faUnlockAlt, faSyncAlt, faEyeSlash, faEye, faCommentCheck, faComment, faPinterest, faVimeo, faTiktok, faList, faListSolid, faGlobeAfrica, faGlobeAfricaSolid, faCommentMinus, faComment, faClock, faRedo, faRedoSolid, faSlidersH, faSlidersHSolid);
Vue.component('font-awesome-icon', FontAwesomeIcon);
// Global site tag (gtag) - Google Analytics
import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
    config: { id: "G-YN93J71RLE" }
});
// Add MS Clarity integration
let snippet = `
(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "${process.env.VUE_APP_CLARITY_ID}");
`;
let clarity = document.createElement('script');
clarity.innerHTML = snippet;
document.head.appendChild(clarity);
new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount("#app");
