import { createNamespacedHelpers } from 'vuex';
import AuthService from '@/services/auth';
// Inital state
const state = {
    loggedIn: false,
    token: null,
};
const getters = {
    loggedIn: (state) => state.loggedIn,
    token: (state) => state.token,
};
const mutations = {
    SET_LOGGEDIN: (state, loggedIn) => (state.loggedIn = loggedIn),
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
};
const actions = {
    async login({ commit, dispatch }, payload) {
        try {
            const response = await AuthService.login(payload);
            await this.commit('auth/SET_TOKEN', response.data.token);
            await this.commit('auth/SET_LOGGEDIN', true);
            await this.dispatch('user/setProfile', { user: response.data.user });
            await this.dispatch('app/setLang', 'en');
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async logout({ commit, dispatch }) {
        // Reset the store state
        this.commit('auth/SET_TOKEN', '');
        this.commit('auth/SET_LOGGEDIN', false);
        this.commit('user/SET_PROFILE', null);
        this.commit('user/SET_COVERIMAGE', null);
        this.commit('user/SET_ONBOARDING', false);
        // close nav
        this.commit('app/SET_NAV', false);
        // todo: clear store
        return true;
    },
    async register({ commit, dispatch }, payload) {
        try {
            const response = await AuthService.register(payload);
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async sendVerifyToken({}, payload) {
        try {
            const response = await AuthService.sendVerifyToken(payload);
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async verifyUserToken({ commit, dispatch }, payload) {
        try {
            const response = await AuthService.verifyUser(payload);
            await this.commit('auth/SET_TOKEN', response.data.token);
            await this.commit('auth/SET_LOGGEDIN', true);
            await this.dispatch('user/setProfile', { user: response.data.user });
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async requestResetPasswordToken({}, email) {
        try {
            const response = await AuthService.requestReset(email);
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async updatePassword({ commit, dispatch }, payload) {
        try {
            const response = await AuthService.updatePassword(payload);
            this.commit('auth/SET_TOKEN', response.data.token);
            this.commit('auth/SET_LOGGEDIN', true);
            await this.dispatch('user/setProfile', { user: response.data.user });
            await this.dispatch('app/setLang', 'en');
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('AuthModule');
export const AuthModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
