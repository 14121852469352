import { createNamespacedHelpers } from 'vuex';
import { i18n } from '@/i18n';
import AppService from '@/services/app';
// Inital state
const state = {
    navOpen: false,
    offline: false,
    updating: false,
    loading: false,
    theme: 'light',
    lang: 'en',
    countries: [],
    currencies: [],
    selectedCurrency: null,
    mediaSize: 0
};
// Gets the values of our state usually called from components
const getters = {
    navOpen: (state) => state.navOpen,
    offline: (state) => state.offline,
    updating: (state) => state.updating,
    loading: (state) => state.loading,
    theme: (state) => state.theme,
    lang: (state) => state.lang,
    countries: (state) => state.countries,
    currencies: (state) => state.currencies,
    selectedCurrency: (state) => state.selectedCurrency,
    mediaSize: (state) => state.mediaSize
};
// Sets values or changes to state. Must be called via an action.
const mutations = {
    START_LOADING: (state) => (state.loading = true),
    END_LOADING: (state) => (state.loading = false),
    CHANGE_THEME: (state, theme) => (state.theme = theme),
    SET_NAV: (state, navStatus) => (state.navOpen = navStatus),
    SET_LANG: (state, lang) => (state.lang = lang),
    SET_COUNTRIES: (state, countries) => (state.countries = countries),
    SET_CURRENCIES: (state, currencies) => (state.currencies = currencies),
    SET_SELECTED_CURRENCY: (state, currency) => (state.selectedCurrency = currency),
    SET_MEDIA_SIZE: (state, mediaSize) => state.mediaSize = mediaSize,
};
// Async functions that will contain the logic / service calls and commit mutations to state
const actions = {
    cleanLocalStorage({ commit, dispatch }, loading) {
        this.commit('lodges/SET_EMPTY');
        this.commit('packages/SET_EMPTY');
        this.commit('stories/SET_EMPTY');
        this.commit('projects/SET_EMPTY');
        this.commit('discussions/SET_EMPTY');
        this.commit('wishlists/SET_EMPTY');
        this.commit('conversations/SET_EMPTY');
        this.commit('members/SET_EMPTY');
    },
    toggleLoading({ commit, dispatch }, loading) {
        if (loading) {
            commit('START_LOADING');
        }
        else {
            commit('END_LOADING');
        }
    },
    toggleTheme({ commit, dispatch, getters }) {
        if (getters.theme == 'light') {
            commit('CHANGE_THEME', 'dark');
        }
        else {
            commit('CHANGE_THEME', 'light');
        }
    },
    initalizeApp({ commit, dispatch, rootGetters }) {
        // Clear the loading should it be stuck
        commit('END_LOADING');
        // Make sure all notifications are hidden on app init
        this.commit('notification/HIDE_NOTIFICATION');
        // Set the base language on app load
        this.dispatch('app/setLang', rootGetters['app/lang']);
    },
    setNav({ commit }, navStatus) {
        commit('SET_NAV', navStatus);
    },
    setCurrency({ commit }, currency) {
        commit('SET_SELECTED_CURRENCY', currency);
    },
    async setLang({ commit }, lang) {
        this.commit('app/SET_LANG', lang);
        i18n.locale = lang;
    },
    async getCountries({ commit, dispatch, getters }) {
        try {
            const response = await AppService.getCountries();
            this.commit('app/SET_COUNTRIES', response.data.countries);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getCurrencies({ commit, dispatch, getters }) {
        try {
            const response = await AppService.getCurrencies();
            this.commit('app/SET_CURRENCIES', response.data.currencies);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async deleteImage({}, image) {
        try {
            const response = await AppService.deleteImage(image);
            await this.commit('user/SET_PROFILE', response.data.user);
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
        }
    },
    calcBreakPoint({ commit }, width) {
        let type = 1;
        if (width >= 768)
            type = 2;
        if (width >= 1024)
            type = 3;
        if (width >= 1280)
            type = 4;
        if (width >= 1536)
            type = 5;
        commit('SET_MEDIA_SIZE', type);
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('AppModule');
export const AppModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
