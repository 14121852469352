import axios from 'axios';
import Store from '../store';
import router from '../router';
export default () => {
    const instance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
            Accept: 'application/json',
            withCredentials: true,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Store.state.auth.token}`,
        },
    });
    instance.interceptors.response.use(function (response) {
        return response;
    }, async function (error) {
        if (error.response && error.response.status === 401) {
            await Store.dispatch('auth/logout');
            await router.push('/login');
        }
        if (error.message.includes('timeout')) {
            throw {
                response: {
                    data: {
                        success: false,
                        message: 'There is a connection problem. Please try again.',
                    },
                },
            };
        }
        throw error;
    });
    return instance;
};
