import { createNamespacedHelpers } from 'vuex';
import PackageService from '@/services/packages.ts';
import Store from '@/store';
// Inital state
const state = {
    getPackages: [],
    getWishlistPackages: [],
    filter: '',
    page: 0,
    onlyFavourites: false,
    onlyNonMalaria: false,
    onlySpecialOffers: false,
    // Experience
    experiences: [],
    selectedExperience: null,
    activePackage: {
        id: '',
        representative_id: '',
        name: '',
        slug: '',
        location: '',
        lat: '',
        lng: '',
        special_offer: false,
        excerpt: '',
        description: '',
        rate_disclaimer: '',
        discount_code: '',
        discount_description: '',
        hero_image_id: '',
        hero_image_copyright: '',
        rates: [],
        images: [],
        representative: null,
        social_links: [],
        related_links: [],
        favorite_packages: [],
        wishlists: [],
        hero_image: null,
        deleted_at: '',
    },
};
const getters = {
    getPackages: (state) => state.getPackages,
    getWishlistPackages: (state) => state.getWishlistPackages,
    activePackage: (state) => state.activePackage,
    getFilter: (state) => state.filter,
    getPage: (state) => state.page,
    getOnlyFavourites: (state) => state.onlyFavourites,
    getOnlyNonMalaria: (state) => state.onlyNonMalaria,
    getOnlySpecialOffers: (state) => state.onlySpecialOffers,
    // Experience
    experiences: (state) => state.experiences,
    selectedExperience: (state) => state.selectedExperience,
};
const mutations = {
    SET_EMPTY: (state) => {
        state.getPackages = [];
        state.experiences = [];
        state.activePackage = {
            id: '',
            representative_id: '',
            name: '',
            slug: '',
            location: '',
            lat: '',
            lng: '',
            special_offer: false,
            excerpt: '',
            description: '',
            rate_disclaimer: '',
            discount_code: '',
            discount_description: '',
            hero_image_id: '',
            hero_image_copyright: '',
            rates: [],
            images: [],
            representative: null,
            social_links: [],
            related_links: [],
            favorite_packages: [],
            wishlists: [],
            hero_image: null,
            deleted_at: '',
        };
    },
    SET_PACKAGES: (state, getPackages) => (state.getPackages = getPackages),
    SET_WISHLIST_PACKAGES: (state, packages) => (state.getWishlistPackages = packages),
    SET_ACTIVE_PACKAGE: (state, getPackage) => (state.activePackage = getPackage),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGE: (state, page) => (state.page = page),
    SET_ONLY_FAVOURITES: (state, onlyFavourites) => (state.onlyFavourites = onlyFavourites),
    SET_ONLY_NON_MALARIA: (state, onlyNonMalaria) => (state.onlyNonMalaria = onlyNonMalaria),
    SET_ONLY_SPECIAL_OFFERS: (state, onlySpecialOffers) => (state.onlySpecialOffers = onlySpecialOffers),
    // Experience
    SET_EXPERIENCES: (state, experiences) => (state.experiences = experiences),
    SET_SELECTED_EXPERIENCE: (state, experience) => (state.selectedExperience = experience),
};
const actions = {
    async setFilter({ commit }, filter) {
        try {
            this.commit('packages/SET_FILTER', filter);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setPage({ commit }, page) {
        try {
            this.commit('packages/SET_PAGE', page);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setOnlyFavourites({ commit }, onlyFavourites) {
        try {
            this.commit('packages/SET_ONLY_FAVOURITES', onlyFavourites);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setOnlyNonMalaria({ commit }, onlyNonMalaria) {
        try {
            this.commit('packages/SET_ONLY_NON_MALARIA', onlyNonMalaria);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setOnlySpecialOffers({ commit }, onlySpecialOffers) {
        try {
            this.commit('packages/SET_ONLY_SPECIAL_OFFERS', onlySpecialOffers);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    // Takes the currently set values for search, filter and pagination
    // and returns a list of packages for display
    // BEFORE calling this function be sure to set the filter,search and page values
    async getPackages() {
        try {
            const response = await PackageService.get();
            this.commit('packages/SET_PACKAGES', response.data.packages);
            this.dispatch('pagination/setPagination', response.data.pagination);
            this.dispatch('user/resetSingleResourceCount', 'packages');
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getPackageBySlug({ commit, dispatch, getters }, slug) {
        try {
            const response = await PackageService.getBySlug(slug);
            this.commit('packages/SET_ACTIVE_PACKAGE', response.data.package);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setActivePackage({ commit, dispatch, getters }, activePackage) {
        try {
            this.commit('packages/SET_ACTIVE_PACKAGE', activePackage);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async requestPackageInformation({}, data) {
        try {
            const response = await PackageService.requestInformation(data);
            await this.dispatch('notification/showSuccessModal', response.message);
        }
        catch (e) {
            // await this.dispatch('notification/showError', e.response.data.message)
            throw e.response.data;
        }
    },
    async favourite({}, slug) {
        try {
            const response = await PackageService.favourite(slug);
            await Store.dispatch('packages/setActivePackage', response.data.package);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data.package;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getWishlistPackages({}, slug) {
        try {
            const response = await PackageService.getWishlistPackages(slug);
            this.commit('packages/SET_WISHLIST_PACKAGES', response.data.packages);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
        }
    },
    // Experience
    async getExperiences({ commit, dispatch, getters }) {
        try {
            const response = await PackageService.getExperiences();
            this.commit('packages/SET_EXPERIENCES', response.data.experiences);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setExperience({ commit }, experience) {
        this.commit('packages/SET_SELECTED_EXPERIENCE', experience);
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('PackagesModule');
export const PackagesModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
