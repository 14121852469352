import { createNamespacedHelpers } from 'vuex';
import ProjectService from '@/services/projects.ts';
import Store from '@/store';
// Inital state
const state = {
    projects: [],
    filter: '',
    searchTerm: '',
    page: 0,
    onlyFavourites: false,
    activeProject: {
        id: '',
        representative_id: '',
        name: '',
        slug: '',
        address: '',
        lat: '',
        lng: '',
        excerpt: '',
        description: '',
        representative: null,
        share_links: [],
        relevant_links: [],
        special_requests: [],
        files: [],
        images: [],
        no_donations: false,
        donation_external_url: "",
        more_info_external_url: "",
        hero_image_id: '',
        hero_image_copyright: '',
        payments: [],
        commitments: [],
        favorite_projects: [],
        deleted_at: '',
    }
};
const getters = {
    getProjects: (state) => state.projects,
    activeProject: (state) => state.activeProject,
    getFilter: (state) => state.filter,
    getSearchTerm: (state) => state.searchTerm,
    getPage: (state) => state.page,
    getOnlyFavourites: (state) => state.onlyFavourites
};
const mutations = {
    SET_EMPTY: (state) => {
        state.projects = [];
        state.activeProject = {
            id: '',
            representative_id: '',
            name: '',
            slug: '',
            address: '',
            lat: '',
            lng: '',
            excerpt: '',
            description: '',
            representative: null,
            share_links: [],
            relevant_links: [],
            special_requests: [],
            files: [],
            images: [],
            no_donations: false,
            donation_external_url: "",
            more_info_external_url: "",
            hero_image_id: '',
            hero_image_copyright: '',
            payments: [],
            commitments: [],
            favorite_projects: [],
            deleted_at: '',
        };
    },
    SET_PROJECTS: (state, projects) => (state.projects = projects),
    SET_ACTIVE_PROJECT: (state, project) => (state.activeProject = project),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_SEARCH_TERM: (state, searchTerm) => (state.searchTerm = searchTerm),
    SET_PAGE: (state, page) => (state.page = page),
    SET_ONLY_FAVOURITES: (state, onlyFavourites) => (state.onlyFavourites = onlyFavourites),
};
const actions = {
    async setFilter({ commit }, filter) {
        try {
            this.commit('projects/SET_FILTER', filter);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setSearch({ commit }, search) {
        try {
            this.commit('projects/SET_SEARCH_TERM', search);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setPage({ commit }, page) {
        try {
            this.commit('projects/SET_PAGE', page);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async setOnlyFavourites({ commit }, onlyFavourites) {
        try {
            this.commit('projects/SET_ONLY_FAVOURITES', onlyFavourites);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    // Takes the currently set values for search, filter and pagination
    // and returns a list of projects for display
    // BEFORE calling this function be sure to set the filter,search and page values
    async getProjects() {
        try {
            const response = await ProjectService.get();
            this.commit('projects/SET_PROJECTS', response.data.projects);
            this.dispatch('pagination/setPagination', response.data.pagination);
            this.dispatch('user/resetSingleResourceCount', 'projects');
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async getProjectBySlug({}, slug) {
        try {
            const response = await ProjectService.getBySlug(slug);
            this.commit('projects/SET_ACTIVE_PROJECT', response.data.project);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setActiveProject({ commit, dispatch, getters }, project) {
        try {
            this.commit('projects/SET_ACTIVE_PROJECT', project);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async favourite({}, slug) {
        try {
            const response = await ProjectService.favourite(slug);
            await Store.dispatch('projects/setActiveProject', response.data.project);
            await this.dispatch('notification/showSuccess', response.message);
            return response.data.project;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('ProjectsModule');
export const ProjectsModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
