import Api from './api';
import Store from '../store';
export default {
    // GET
    get() {
        // Sort out whats in the store
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        const current_page = Store.getters['projects/getPage'] ? Store.getters['projects/getPage'] : 1;
        const search_term = Store.getters['projects/getSearchTerm'] ? Store.getters['projects/getSearchTerm'] : null;
        const filter = Store.getters['projects/getFilter'] ? Store.getters['projects/getFilter'] : null;
        const onlyFavourites = Store.getters['projects/getOnlyFavourites'] ? Store.getters['projects/getOnlyFavourites'] : null;
        // Sort out the URL for the projects response
        let url = `/projects?per_page=${per_page}`;
        if (current_page) {
            url += `&page=${current_page}`;
        }
        if (search_term && search_term != '') {
            url += `&q=${search_term}`;
        }
        if (filter && filter != '') {
            url += `&label=${filter}`;
        }
        if (onlyFavourites && onlyFavourites === true) {
            url += `&favorite=true`;
        }
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    getBySlug(slug) {
        return Api()
            .get(`/projects/${slug}`)
            .then((response) => response.data);
    },
    createSubscription(params) {
        return Api()
            .post(`/subscriptions/stripe/add-plan`, params)
            .then((response) => response.data);
    },
    cancelSubscription(params) {
        return Api()
            .post(`/subscriptions/stripe/remove-plan`, params)
            .then((response) => response.data);
    },
    favourite(slug) {
        return Api()
            .post(`/projects/${slug}/favorite`)
            .then((response) => response.data);
    },
};
