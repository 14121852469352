import Api from './api';
import store from "@/store/index";
export default {
    // GET
    getUserProfile() {
        return Api()
            .get(`profile`)
            .then((response) => response.data);
    },
    getUserInvoices() {
        return Api()
            .get(`subscriptions/stripe/user-invoices`)
            .then((response) => response.data);
    },
    downloadInvoice(id) {
        return Api()
            .get(`subscriptions/stripe/user-invoices/${id}`, { responseType: 'blob' })
            .then((response) => response.data);
    },
    // PUT
    update(user) {
        return Api()
            .put('profile', user)
            .then((response) => response.data);
    },
    verifyEmailChange(payload) {
        return Api()
            .post('profile/verify-email-change', payload)
            .then((response) => response.data);
    },
    updateLastActivity(resource) {
        return Api()
            .put('profile/last-activity', {
            resource: resource,
        })
            .then((response) => response.data);
    },
    updatePassword(form) {
        return Api()
            .put('profile/password', form)
            .then((response) => response.data);
    },
    deleteAccount(formData) {
        return Api()
            .post('profile/delete', formData, {
            headers: {
                Accept: 'application/json',
                withCredentials: true,
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${store.state.auth.token}`,
            },
        })
            .then((response) => response.data);
    },
    updateAvatar(file) {
        let formData = new FormData();
        formData.append('avatar', file);
        return Api()
            .post('profile/avatar', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
            .then((response) => response.data);
    },
    uploadImage(file) {
        let formData = new FormData();
        formData.append('image', file);
        return Api()
            .post('images', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
            .then((response) => response.data);
    },
    // set the user data into the form.
    // added as a service because it is used in multiple forms
    setUserForm() {
        const profile = store.getters['user/getProfile'];
        const userForm = {
            name: profile.name ?? '',
            email: profile.email ?? '',
            description: profile.description ?? '',
            country_code: profile.country_code ?? '',
            city: profile.city ?? '',
            labels: profile.labels ?? [],
            phone: profile.phone ?? '',
            address: profile.address ?? '',
            company_name: profile.company_name ?? '',
            company_phone: profile.company_phone ?? '',
            company_address: profile.company_address ?? '',
            onboarding: profile.onboarding ?? false,
            email_notification_messages: profile.email_notification_messages ?? false
        };
        return userForm;
    },
    async setUserLabelsArray(labels) {
        const profile = store.getters['user/getProfile'];
        let userLabels = [];
        if (profile.labels) {
            userLabels = await profile.labels?.map((label) => {
                return label.id;
            });
        }
        return userLabels;
    }
};
