import { createNamespacedHelpers } from 'vuex';
import { UserProfileModel } from '@/models/User.model';
import UserService from '@/services/user';
const state = {
    profile: new UserProfileModel(),
    onboarding: false,
    coverImage: '',
    resourceCount: {
        discussions: 0,
        lodges: 0,
        members: 0,
        projects: 0,
        stories: 0,
        messages: 0
    },
    supportUser: new UserProfileModel(),
    safariExpert: new UserProfileModel()
};
const getters = {
    getProfile: (state) => state.profile,
    labels: (state) => state.profile.labels,
    onboarding: (state) => state.onboarding,
    getCoverImage: (state) => state.coverImage,
    getResourceCount: (state) => state.resourceCount,
    getSupportUser: (state) => state.supportUser,
    getSafariExpert: (state) => state.safariExpert,
};
const mutations = {
    SET_PROFILE: (state, user) => (state.profile = user),
    SET_LABELS: (state, labels) => (state.profile.labels = labels),
    SET_ONBOARDING: (state, onboarding) => (state.onboarding = onboarding),
    SET_COVERIMAGE: (state, coverImage) => (state.coverImage = coverImage),
    SET_RESOURCE_COUNT: (state, resourceCount) => (state.resourceCount = resourceCount),
    RESET_SINGLE_RESOURCE_COUNT: (state, resourceName) => (state.resourceCount[resourceName] = 0),
    SET_SUPPORT_USER: (state, supportUser) => (state.supportUser = supportUser),
    SET_SAFARI_EXPERT: (state, safariExpert) => (state.safariExpert = safariExpert),
};
const actions = {
    async getUserProfile({ commit, dispatch }) {
        try {
            const response = await UserService.getUserProfile();
            await this.commit('user/SET_PROFILE', response.data.profile);
            await this.commit('user/SET_ONBOARDING', response.data.profile.onboarding);
            await this.commit('user/SET_RESOURCE_COUNT', response.data.resources_count);
            await this.commit('user/SET_SUPPORT_USER', response.data.support_user);
            await this.commit('user/SET_SAFARI_EXPERT', response.data.safari_expert);
            return response.message;
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
        }
    },
    async setProfile({ commit, dispatch }, { user }) {
        await this.commit('user/SET_PROFILE', user);
    },
    async updateUser({ commit, dispatch }, user) {
        try {
            const response = await UserService.update(user);
            await this.commit('user/SET_PROFILE', response.data.profile);
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
        }
    },
    async updatePassword({ commit, dispatch }, form) {
        try {
            const response = await UserService.updatePassword(form);
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
        }
    },
    async updateUserAvatar({}, file) {
        try {
            const response = await UserService.updateAvatar(file);
            await this.commit('user/SET_PROFILE', response.data.user);
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
        }
    },
    async uploadImage({}, image) {
        try {
            const response = await UserService.uploadImage(image);
            this.commit('user/SET_PROFILE', response.data.user);
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
        }
    },
    setOnboarding({ commit }, onboarding) {
        commit('SET_ONBOARDING', onboarding);
    },
    setCoverImage({ commit }, imagePath) {
        commit('SET_COVERIMAGE', imagePath);
    },
    resetSingleResourceCount({ commit }, resourceName) {
        commit('RESET_SINGLE_RESOURCE_COUNT', resourceName);
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('UserModule');
export const UserModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
