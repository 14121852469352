import Api from './api';
import Store from '@/store';
import axios from 'axios';
export default {
    // GET
    get() {
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        const current_page = Store.getters['push_notifications/getPage'] ? Store.getters['push_notifications/getPage'] : 1;
        let url = `/notifications?per_page=${per_page}`;
        if (current_page) {
            url += `&page=${current_page}`;
        }
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    markRead(notification_id) {
        return Api()
            .put(`/notifications/${notification_id}`)
            .then((response) => response.data);
    },
    deleteNotification(notification_id) {
        return Api()
            .delete(`/notifications/${notification_id}`)
            .then((response) => response.data);
    },
    trackCustomNotification(push_id) {
        // console.log(push_id)
        return Api()
            .post(`/admin-notifications`, {
            push_id: push_id,
            event_type: "OPEN"
        })
            .then((response) => response.data);
    },
    subscribe() {
        // console.log(`notifications.ts - 22 `);
        navigator.serviceWorker.ready.then(registration => {
            const options = { userVisibleOnly: true };
            const vapidPublicKey = "BDddWZGbEv3dSlao4FSVUKYTw7Nd0Rn6kganDoTJEdLaSZO9WcUufOWrqbvsbHBFz1wn6mf_25gL0hGLEpoirss";
            if (vapidPublicKey) {
                options.applicationServerKey = this.urlBase64ToUint8Array(vapidPublicKey);
            }
            registration.pushManager
                .subscribe(options)
                .then(subscription => {
                this.updateSubscription(subscription);
            })
                .catch(e => {
                if (Notification.permission === "denied") {
                    // console.log("Permission for Notifications was denied");
                }
                else {
                    // console.log("Unable to subscribe to push.", e);
                }
            });
        });
    },
    /**
     * Unsubscribe from push notifications.
     */
    unsubscribe() {
        navigator.serviceWorker.ready.then(registration => {
            registration.pushManager
                .getSubscription()
                .then(subscription => {
                if (!subscription) {
                    return;
                }
                subscription
                    .unsubscribe()
                    .then(() => {
                    this.deleteSubscription(subscription);
                })
                    .catch(e => {
                    // console.log("Unsubscription error: ", e);
                });
            })
                .catch(e => {
                // console.log("Error thrown while unsubscribing.", e);
            });
        });
    },
    /**
     * Send a request to the server to update user's subscription.
     *
     * @param {PushSubscription} subscription
     */
    updateSubscription(subscription) {
        const key = subscription.getKey("p256dh");
        const token = subscription.getKey("auth");
        // @ts-ignore
        const data = {
            endpoint: subscription.endpoint,
            key: key
                // @ts-ignore
                ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
                : null,
            token: token
                // @ts-ignore
                ? btoa(String.fromCharCode.apply(null, new Uint8Array(token)))
                : null
        };
        // Try to check the auth token, will fail if not valid
        // if not valid we do not update subscription
        try {
            const instance = axios.create({
                baseURL: process.env.VUE_APP_API_URL,
                headers: {
                    Accept: 'application/json',
                    withCredentials: true,
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Store.state.auth.token}`,
                },
            });
            instance
                .post('/subscriptions', data)
                .then(() => {
                // console.log('subscribed')
            })
                .catch((error) => {
                // console.log(`notifications.ts - 92 - error`, error)
            });
        }
        catch (error) {
            // console.log(`notifications.ts - 85 - error`, error)
        }
    },
    /**
     * Send a requst to the server to delete user's subscription.
     *
     * @param {PushSubscription} subscription
     */
    deleteSubscription(subscription) {
        Api()
            .post("/subscriptions/delete", { endpoint: subscription.endpoint })
            .then(() => {
            // console.log(`notifications.ts - 116 `);
        });
    },
    /**
     * https://github.com/Minishlink/physbook/blob/02a0d5d7ca0d5d2cc6d308a3a9b81244c63b3f14/app/Resources/public/js/app.js#L177
     *
     * @param  {String} base64String
     * @return {Uint8Array}
     */
    urlBase64ToUint8Array(base64String) {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, "+")
            .replace(/_/g, "/");
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    },
};
