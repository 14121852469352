import Api from './api';
import Store from '@/store';
export default {
    // !!SCALABILITY FIX!!
    getCache() {
        return Api()
            .get(`/lodges/cache`)
            .then((response) => response.data);
    },
    // GET ALL
    getAll() {
        // Sort out whats in the store
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        const current_page = Store.getters['lodges/getPage'] ? Store.getters['lodges/getPage'] : 1;
        const search_term = Store.getters['lodges/getSearchTerm'] ? Store.getters['lodges/getSearchTerm'] : null;
        const filter = Store.getters['lodges/getFilter'] ? Store.getters['lodges/getFilter'] : null;
        const onlyFavourites = Store.getters['lodges/getOnlyFavourites'] ? Store.getters['lodges/getOnlyFavourites'] : null;
        const onlyNonMalaria = Store.getters['lodges/getOnlyNonMalaria'] ? Store.getters['lodges/getOnlyNonMalaria'] : null;
        const selectedDestination = Store.getters['destinations/selectedDestination'] ? Store.getters['destinations/selectedDestination'] : null;
        // Sort out the URL for the lodges response
        let url = `/lodges?per_page=${per_page}`;
        if (current_page) {
            url += `&page=${current_page}`;
        }
        if (search_term && search_term != '') {
            url += `&q=${search_term}`;
        }
        if (filter && filter != '') {
            url += `&label=${filter}`;
        }
        if (onlyFavourites && onlyFavourites === true) {
            url += `&favorite=true`;
        }
        if (onlyNonMalaria && onlyNonMalaria === true) {
            url += `&non_malaria=true`;
        }
        if (selectedDestination && selectedDestination != '') {
            url += `&destination=${selectedDestination}`;
        }
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    getAllSearch() {
        // Sort out whats in the store
        const per_page = 1000;
        const search_term = Store.getters['lodges/getSearchTerm'] ? Store.getters['lodges/getSearchTerm'] : null;
        const filter = Store.getters['lodges/getFilter'] ? Store.getters['lodges/getFilter'] : null;
        const onlyFavourites = Store.getters['lodges/getOnlyFavourites'] ? Store.getters['lodges/getOnlyFavourites'] : null;
        const onlyNonMalaria = Store.getters['lodges/getOnlyNonMalaria'] ? Store.getters['lodges/getOnlyNonMalaria'] : null;
        const selectedDestination = Store.getters['destinations/selectedDestination'] ? Store.getters['destinations/selectedDestination'] : null;
        // Sort out the URL for the lodges response
        let url = `/lodges?per_page=${per_page}`;
        if (search_term && search_term != '') {
            url += `&q=${search_term}`;
        }
        if (filter && filter != '') {
            url += `&label=${filter}`;
        }
        if (onlyFavourites && onlyFavourites === true) {
            url += `&favorite=true`;
        }
        if (onlyNonMalaria && onlyNonMalaria === true) {
            url += `&non_malaria=true`;
        }
        if (selectedDestination && selectedDestination != '') {
            url += `&destination=${selectedDestination}`;
        }
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    // GET
    get() {
        // Sort out whats in the store
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        const current_page = Store.getters['lodges/getPage'] ? Store.getters['lodges/getPage'] : 1;
        const search_term = Store.getters['lodges/getSearchTerm'] ? Store.getters['lodges/getSearchTerm'] : null;
        const filter = Store.getters['lodges/getFilter'] ? Store.getters['lodges/getFilter'] : null;
        const onlyFavourites = Store.getters['lodges/getOnlyFavourites'] ? Store.getters['lodges/getOnlyFavourites'] : null;
        const onlyNonMalaria = Store.getters['lodges/getOnlyNonMalaria'] ? Store.getters['lodges/getOnlyNonMalaria'] : null;
        const selectedDestination = Store.getters['destinations/selectedDestination'] ? Store.getters['destinations/selectedDestination'] : null;
        // Sort out the URL for the lodges response
        let url = `/lodges?per_page=${per_page}`;
        if (current_page) {
            url += `&page=${current_page}`;
        }
        if (search_term && search_term != '') {
            url += `&q=${search_term}`;
        }
        if (filter && filter != '') {
            url += `&label=${filter}`;
        }
        if (onlyFavourites && onlyFavourites === true) {
            url += `&favorite=true`;
        }
        if (onlyNonMalaria && onlyNonMalaria === true) {
            url += `&non_malaria=true`;
        }
        if (selectedDestination && selectedDestination != '') {
            url += `&destination=${selectedDestination}`;
        }
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    getBySlug(slug) {
        return Api()
            .get(`/lodges/${slug}`)
            .then((response) => response.data);
    },
    paginate(page) {
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        return Api()
            .get(`/lodges?page=${page}&per_page=${per_page}`)
            .then((response) => response.data);
    },
    search(searchValue) {
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        return Api()
            .get(`/lodges?q=${searchValue}&per_page=${per_page}`)
            .then((response) => response.data);
    },
    requestInformation(requestData) {
        return Api()
            .post(`lodges/request-info/${requestData.slug}`, { name: requestData.name, email: requestData.email, comment: requestData.comment, recaptchaToken: requestData.recaptchaToken })
            .then((response) => response.data);
    },
    favourite(slug) {
        return Api()
            .post(`/lodges/${slug}/favorite`)
            .then((response) => response.data);
    },
};
