export default {
    landing: {
        register: '__',
        login: '__'
    },
    auth: {
        reset_password_heading: '__',
        send_reset_token: '__',
        or: '__',
        back_to_login: '__',
        reset_password: '__',
        no_account: `__`,
        forgot_password: '__'
    },
    form: {
        button: {
            login: '__',
            register: '__',
            save: '__',
            cancel: '__',
            verify: '__',
            update: '__',
            edit: '__',
            submit: '__',
            next: '__',
        },
        register: {
            verify_email: '__',
            complete: '__'
        },
        token: {
            label: '__',
            placeholder: '__',
        },
        name: {
            label: '__',
            placeholder: '__',
        },
        language: {
            label: '__',
        },
        email: {
            label: '__',
            placeholder: '__',
        },
        password: {
            label: '__',
            placeholder: '__',
        },
        register_token: {
            label: '__',
            placeholder: '__',
        },
        labels: {
            label: '__',
            placeholder: '__',
            empty: '__',
        },
        users: {
            label: '__',
            placeholder: '__',
            empty: '__'
        },
        profile: {
            description: '__',
        }
    },
    search: {
        sort_by: '__',
    },
    base: {
        yes: '__',
        no: '__',
        true: '__',
        false: '__',
    }
};
