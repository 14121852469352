import { createNamespacedHelpers } from 'vuex';
import DestinationService from '@/services/destinations.ts';
// Inital state
const state = {
    destinations: [],
    selectedDestination: null,
};
const getters = {
    destinations: (state) => state.destinations,
    selectedDestination: (state) => state.selectedDestination,
};
const mutations = {
    SET_EMPTY: (state) => {
        state.destinations = [];
        state.selectedDestination = null;
    },
    SET_DESTINATIONS: (state, destinations) => (state.destinations = destinations),
    SET_SELECTED_DESTINATION: (state, destination) => (state.selectedDestination = destination),
};
const actions = {
    async getDestinations({ commit, dispatch, getters }, model_type) {
        try {
            const response = await DestinationService.getDestinations(model_type);
            this.commit('destinations/SET_DESTINATIONS', response.data.destinations);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async setDestination({ commit }, destination) {
        this.commit('destinations/SET_SELECTED_DESTINATION', destination);
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('DestinationsModule');
export const DestinationsModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
