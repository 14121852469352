import Api from './api';
export default {
    agree(agreeable) {
        return Api()
            .post(`/engagements/agree`, agreeable)
            .then((response) => response.data);
    },
    disagree(disagreeable) {
        return Api()
            .post(`/engagements/disagree`, disagreeable)
            .then((response) => response.data);
    },
    report(reportable) {
        return Api()
            .post(`/engagements/report`, reportable)
            .then((response) => response.data);
    },
    comment(commentable) {
        return Api()
            .post(`/engagements/comments`, commentable)
            .then((response) => response.data);
    },
    // Update comment
    updateComment(form) {
        return Api()
            .put(`/engagements/comments/${form.comment_id}`, form)
            .then((response) => response.data);
    },
    // delete
    deleteComment(id) {
        return Api()
            .delete(`/engagements/comments/${id}`)
            .then((response) => response.data);
    }
};
