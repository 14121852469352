import Api from './api';
import Store from '../store';
export default {
    // GET
    get() {
        // Sort out whats in the store
        const per_page = Store.getters['pagination/per_page_count'] ? Store.getters['pagination/per_page_count'] : 6;
        const current_page = Store.getters['members/getPage'] ? Store.getters['members/getPage'] : 1;
        const search_term = Store.getters['members/getSearchTerm'] ? Store.getters['members/getSearchTerm'] : null;
        const filter = Store.getters['members/getFilter'] ? Store.getters['members/getFilter'] : null;
        // Sort out the URL for the members response
        let url = `/members?per_page=${per_page}`;
        if (current_page) {
            url += `&page=${current_page}`;
        }
        if (search_term && search_term != '') {
            url += `&q=${search_term}`;
        }
        if (filter && filter != '') {
            url += `&label=${filter}`;
        }
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    getBySlug(slug) {
        return Api()
            .get(`/members/${slug}`)
            .then((response) => response.data);
    },
    blockMember(blocked_member_id) {
        return Api()
            .post(`/members/block`, {
            blocked_member_id: blocked_member_id
        })
            .then((response) => response.data);
    },
};
