import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Getter } from 'vuex-class';
import Btn from '@/components/Button/Btn.vue';
let Notification = class Notification extends Vue {
    mounted() { }
    get displayIcon() {
        return this.status === 'success' ? ['fal', 'check-circle'] : ['fal', 'times-circle'];
    }
    hide() {
        this.$store.dispatch('notification/hideNotification');
    }
};
__decorate([
    Getter('status', { namespace: 'notification' })
], Notification.prototype, "status", void 0);
Notification = __decorate([
    Component({
        components: {
            Btn
        },
        computed: mapGetters({
            message: 'notification/message',
            display: 'notification/display',
            sticky: 'notification/sticky',
            position: 'notification/position',
            guest: 'notification/guest',
        }),
    })
], Notification);
export default Notification;
