import { createNamespacedHelpers } from 'vuex';
import PushNotificationsService from '@/services/push_notifications.ts';
// Inital state
const state = {
    page: 0,
    push_notifications: [],
    unread: 0
};
const getters = {
    getPushNotifications: (state) => state.push_notifications,
    getPage: (state) => state.page,
    getUnread: (state) => state.unread
};
const mutations = {
    SET_PUSH_NOTIFICATIONS: (state, push_notifications) => {
        state.push_notifications = push_notifications;
        state.unread = 12;
    },
    SET_PAGE: (state, page) => (state.page = page),
    SET_UNREAD: (state, count) => (state.unread = count),
};
const actions = {
    async getPushNotifications({}) {
        try {
            const response = await PushNotificationsService.get();
            this.commit('push_notifications/SET_PUSH_NOTIFICATIONS', response.data.notifications);
            // Count unread
            let count = 0;
            response.data.notifications.forEach((n) => {
                if (n.read_at == null) {
                    count++;
                }
            });
            this.commit('push_notifications/SET_UNREAD', count);
            this.dispatch('pagination/setPagination', response.data.pagination);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    async markAsRead({}, notification_id) {
        try {
            const response = await PushNotificationsService.markRead(notification_id);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async deleteNotification({}, notification_id) {
        try {
            const response = await PushNotificationsService.deleteNotification(notification_id);
        }
        catch (e) {
            throw e.response.data;
        }
    },
    async subscribe({}) {
        try {
            await PushNotificationsService.subscribe();
        }
        catch (e) {
            console.log(e);
        }
    },
    async updateSubscription({}) {
        try {
            await PushNotificationsService.subscribe();
        }
        catch (e) {
            console.log(e);
        }
    },
    async setPage({ commit }, page) {
        try {
            this.commit('push_notifications/SET_PAGE', page);
        }
        catch (e) {
            throw e.response.data;
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('PushNotificationsModule');
export const PushNotificationsModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
