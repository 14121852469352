import Api from './api';
export default {
    // GET
    get(perPage) {
        // Sort out the URL for the conversations response
        let url = `/messages?perPage=${perPage}`;
        return Api()
            .get(url)
            .then((response) => response.data);
    },
    getBySlug(slug) {
        return Api()
            .get(`/messages/${slug}`)
            .then((response) => response.data);
    },
    // POST
    create(form) {
        return Api()
            .post('/messages', form)
            .then((response) => response.data);
    },
    // DELETE
    delete(id) {
        return Api()
            .delete(`/messages/${id}`)
            .then((response) => response.data);
    },
    deleteConversation(id) {
        return Api()
            .delete(`/messages/${id}/delete-conversation`)
            .then((response) => response.data);
    },
};
