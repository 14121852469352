import { createNamespacedHelpers } from 'vuex';
import LabelsService from '@/services/labels';
// Inital state
const state = {
    labels: [],
};
const getters = {
    getLabels: (state) => state.labels,
    labelById: (state) => (id) => state.labels.filter((label) => label.id == id),
};
const mutations = {
    ADD_LABEL_TO_LABELS: (state, label) => state.labels.push(label),
    SET_LABELS: (state, labels) => (state.labels = labels),
};
const actions = {
    async getAllLabels({ commit, dispatch, getters }) {
        try {
            const response = await LabelsService.get();
            this.commit('label/SET_LABELS', response.data.labels);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
    // POST
    async createLabel({ commit, dispatch, getters }, label) {
        try {
            const response = await LabelsService.create(label);
            this.commit('labels/ADD_LABEL_TO_LABELS', response.data.label);
            await this.dispatch('notification/showSuccess', response.message);
        }
        catch (e) {
            await this.dispatch('notification/showError', e.response.data.message);
            throw e.response.data;
        }
    },
};
export const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('LabelModule');
export const LabelModule = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
