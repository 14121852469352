import Api from './api';
export default {
    // GET
    get() {
        return Api()
            .get('/labels')
            .then((response) => response.data);
    },
    // POST
    create(label) {
        return Api()
            .post(`/labels`, label)
            .then((response) => response.data);
    },
};
